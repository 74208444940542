import React, { useState } from "react";

import Pagination from "react-bootstrap/Pagination";

import { Link } from "react-router-dom";
import UserService from "../../services/userService";
import lmsService from "../../services/lmsService";
import userService from "../../services/userService";
import Banner from "../Banner/banner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ReactHtmlParser from "react-html-parser";
import { Formik, ErrorMessage, FieldArray } from "formik";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import utils from '../../utils/util';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const FeedBackForm = (props) => {
  let userServ = new userService();
  const [redirect, setredirect] = useState(false);
  const [student, setstudent] = useState({
    userId: window.user.data._id,
    courseId: props.match.params.id,
    teacherId: props.location.state?.teacherId,
    questions: props.location.state?.questions || [],
    form_type: props.location.state?.type,
  });
  const submitForm = (values, action) => {
    userServ
      .sumbitUserFeedBack(values)
      .then((res) => {
        toast.success(utils.intlLang("Feedback submitted successfully "));
        setTimeout(() => {
          setredirect(true);
        }, 1000);
      })
      .catch((err) => {
        setredirect(true);
        toast.error(utils.intlLang("Feedback submitted successfully "));
      });
  };
  if (redirect) {
    return <Redirect to="/user/profile" />;
  }
  if (!props.location.state) {
    return <Redirect to="/user/profile" />;
  }
  return (
    <>
      <Formik initialValues={student} enableReinitialize={true} onSubmit={submitForm}>
        {({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => {
          return (
            <div className="address addresslist">
              <Container>
                <div style={{ marginTop: "20px" }}></div>
                <h2 className="title">{props.location.state?.title}</h2>
                {props.location.state?.type == "course_evaluation" && (
                  <p>
                    {utils.intlLang("Thanks for choosing our course and hope you found it beneficial. Please spend a few minutes to gives us your thoughts about the course and any comments and suggestions. Thanks again for your time.")}
                  </p>
                )}
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col></Col>
                    <FieldArray>
                      {({ form }) => {
                        const { values } = form;
                        return values.questions.map((el, i) => {
                          if (values.form_type == "course_evaluation") {
                            if (i <= 9) {
                              return (
                                <>
                                  <Col sm={9} md={6} key={el.question}>
                                    <Form.Label>{el.question}</Form.Label>
                                    <Form.Control
                                      as="select"
                                      name={`questions.${[i]}.answer`}
                                      value={el.answer}
                                      onChange={handleChange}
                                    >
                                      <option value="">{utils.intlLang("Select")}</option>
                                      <option value="poor">{utils.intlLang("Poor")}</option>
                                      <option value="unsatisfactory">{utils.intlLang("Unsatisfactory")}</option>
                                      <option value="acceptable">{utils.intlLang("Acceptable")}</option>
                                      <option value="satisfactory">{utils.intlLang("Satisfactory")}</option>
                                      <option value="excellent">{utils.intlLang("Excellent")}</option>
                                    </Form.Control>
                                  </Col>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <Col sm={9} md={6} key={el.question}>
                                    <Form.Group>
                                      <Form.Label>{el.question}</Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows="3"
                                        placeholder="Answer"
                                        value={el.answer}
                                        name={`questions.${[i]}.answer`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <ErrorMessage name={`questions.${[i]}.answer`}>
                                        {(msg) => <div className="err_below">{msg}</div>}
                                      </ErrorMessage>
                                    </Form.Group>
                                  </Col>
                                </>
                              );
                            }
                          } else {
                            if (i <= 12) {
                              return (
                                <>
                                  <Col sm={9} md={6} key={el.question}>
                                    <Form.Label>{el.question}</Form.Label>
                                    <Form.Control
                                      as="select"
                                      name={`questions.${[i]}.answer`}
                                      value={el.answer}
                                      onChange={handleChange}
                                    >
                                      <option value="">{utils.intlLang("Select")}</option>
                                      <option value="poor">{utils.intlLang("Poor")}</option>
                                      <option value="unsatisfactory">{utils.intlLang("Unsatisfactory")}</option>
                                      <option value="acceptable">{utils.intlLang("Acceptable")}</option>
                                      <option value="satisfactory">{utils.intlLang("Satisfactory")}</option>
                                      <option value="excellent">{utils.intlLang("Excellent")}</option>
                                    </Form.Control>
                                  </Col>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <Col sm={9} md={6} key={el.question}>
                                    <Form.Group>
                                      <Form.Label>{el.question}</Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows="3"
                                        placeholder="Answer"
                                        value={el.answer}
                                        name={`questions.${[i]}.answer`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <ErrorMessage name={`questions.${[i]}.answer`}>
                                        {(msg) => <div className="err_below">{msg}</div>}
                                      </ErrorMessage>
                                    </Form.Group>
                                  </Col>
                                </>
                              );
                            }
                          }
                        });
                      }}
                    </FieldArray>
                  </Row>
                  <Row>
                    <Col>
                      <button type="submit" className="btn btn-dark effect btn-sm" id="button1">
                        {" "}
                        <i className="fas fa-save"></i> {utils.intlLang("Save")}
                      </button>
                      <p style={{ marginLeft: "14px" }}> {utils.intlLang("Thanks for your time and Best of Luck.")}</p>
                    </Col>

                    <br />
                  </Row>
                </Form>
              </Container>
            </div>
          );
        }}
      </Formik>
      <ToastContainer />
    </>
  );
};
