import React from "react";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import Banner from "../Banner/banner";
import CourseService from "../../services/courseService";
import userServices from "../../services/userService";
import CourseCategoryService from "../../services/courseCategoryService";
import BannerService from "../../services/bannerService";
import ReactHtmlParser from "react-html-parser";
import Testimonial from "../../services/testimonialService";
import Carousel from "react-bootstrap/Carousel";
import { Helmet } from "react-helmet";
import setting from "../../services/settingService";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import Quizze from "./Quizze/Quizze";
import CourseQuestion from "./CourseEvaluationQus.json";
import BackButton from "../../components/BackButton";
import utils from "../../utils/util";
export default class CourseDetail extends React.Component {
  constructor(props) {
    super(props);
    this.course_id = props.match.params.id;
    this.user = window.user;
    this.ifrme = React.createRef();
    this.state = {
      isFullScreen :false,
      fullsize:false,
      exitfullsize:true,
      lesson_type:'',
      is_disabled:false,
      showoptions:false,
      openFullView: false,
      closeFullView: true,

      viewedLessonList: [] /*lesson viewed list*/,
      lessonChangeCounter: 0,
      course: {
        section: [
          {
            name: "",
            sequence: "",
            lesson: [
              {
                name: "",
                view_duration: "",
                file_path: "",
                lesson_type: "",
                available_for_preview: false,
              },
            ],
          },
        ],
      },
      successMessage: false,
      settingList: [],
      modal: false,
      filename: "",
      file: {
        question: "",
        question_type: "",
        answer_type: "",
        file_path: "",
        Sequence: "",
        option: "",
      },
      sectionId: "",
    };
    this.search = { start: 0, perPage: 12, searchTxt: "", searchField: "" };

    this.bannerServ = new BannerService();
    this.courseServ = new CourseService();

    if (this.course_id) {
      this.courseServ.getCourse(this.course_id).then(
        (response) => {
          this.categoriesOption = [];
          let categoriesData = response.data;
          let lessontype=''
          let sectionId=''
          let filedata={
            question: "",
            question_type: "",
            answer_type: "",
            file_path: "",
            Sequence: "",
            option: "",
          }
          let is_disabled=false
          let fname = "";
          let showoptions = false;
          for (let i = 0; i < categoriesData.categories.length; i++) {
            this.categoriesOption.push(
              categoriesData.categories[i].id.name + "||"
            );
          }
          if (response.data.section.length > 0) {
            for (let i = 0; i < response.data.section.length; i++) {
             if(response.data.section[i].is_disabled==false)
             {
              for (let j = 0; j < response.data.section[i].lesson.length; j++) {
                if(response.data.section[i].lesson[j].is_disabled==false)
                {
                  filedata=response.data.section[i].lesson[j]
                  lessontype=response.data.section[i].lesson[j].lesson_type
                  sectionId=response.data.section[i]._id
                  is_disabled=response.data.section[i].lesson[j].is_disabled
                  fname = response.data.section[i].lesson[j].file_path.split("_");
                  fname = fname[fname.length - 1]
                  showoptions=true
                  break;
                }
              }
              break;
             }
            }
          }
         
         
          this.setState({ 
            course: response.data,
            filename: fname,
            file:filedata,
            showoptions:showoptions,
            lesson_type: lessontype,
            is_disabled: is_disabled,
            sectionId: sectionId
          });
        },
        (error) => {
          alert(
            utils.intlLang(
              "Oops, something went wrong not able to fetch Course  details."
            )
          );
        }
      );
    }
  }
    exitHandler= () => {
    if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      this.setState({
        fullsize: false,
        exitfullsize: true,
        openFullView: false,
        closeFullView: true,
      });
      var element = document.getElementById("fullsize");
      document.getElementById("myId").style.minHeight = "350px";
    }
}
  componentDidMount() {
    this.listViewedCourse();
    if (document.addEventListener) {
      document.addEventListener('webkitfullscreenchange', this.exitHandler, false);
      document.addEventListener('mozfullscreenchange', this.exitHandler, false);
      document.addEventListener('fullscreenchange', this.exitHandler, false);
      document.addEventListener('MSFullscreenChange', this.exitHandler, false);
  }
  }
  handleShow() {
    this.setState({ modal: true });
  }
  handleClose() {
    this.setState({ modal: false });
  }
  handleViewedCourse(section, lesson) {
  
    let sectionId = section._id;
    let lessonId = lesson._id;
    let userId = window.user.data._id;
    let courseId = this.course_id;
    this.courseServ
      .handleViewedCourse({ sectionId, lessonId, userId, courseId })
      .then((res) => {
        let viewedLesson = [...this.state.viewedLessonList];
        viewedLesson.push(res.data);
        this.setState({ viewedLessonList: viewedLesson });
        var video =document.getElementsByTagName('video')[0]
        video.load()
        console.log(this.state.file.lesson_type,'section')
      })
      .catch((err) => {});
      
  }
  listViewedCourse() {
    let userId = window.user.data._id;
    let courseId = this.course_id;
    this.courseServ
      .ListViewedCourse({ userId, courseId })
      .then((res) => {
        this.setState({ viewedLessonList: res.data });
      })
      .catch((err) => {
        this.setState({ viewedLessonList: [] });
      });
  }

  toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.querySelector("#iframe-pdf").requestFullscreen();
      this.setState({ isFullScreen: true });
    } else {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        this.setState({ isFullScreen: false });
      }
    }
  };
  handleFullView = () => {
    this.setState({
      fullsize: true,
      exitfullsize: false,
      closeFullView: false,
    });
    var element = document.getElementById("fullsize");
    document.getElementById("myId").style.backgroundColor = "#000";
    document.getElementById("myId").style.minHeight = "500px";
    element.classList.remove("col-md-8");
    element.classList.add("col-md-12");
  };
  handleexitFullView = () => {
    this.setState({
      fullsize: false,
      exitfullsize: true,
      closeFullView: true,
    });
    var element = document.getElementById("fullsize");
    document.getElementById("myId").style.minHeight = "350px";
    document.getElementById("myId").style.backgroundColor = "#000";
    element.classList.remove("col-md-12");
    element.classList.add("col-md-8");
  };

  // Open full view
  handleOpenFullView = (type) => {
    this.setState({
      fullsize: false,
      exitfullsize: false,
      openFullView: true,
      closeFullView: false,
    });
 
    document.getElementById("myId").style.minHeight = "100%";
    if(type=='video')
    {
        var elemvideo = document.getElementById("myId");
        if (elemvideo.requestFullscreen) {
          elemvideo.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elemvideo.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elemvideo.msRequestFullscreen();
        }
    }
    else
    {
      var elem = document.getElementById("fullsize");
      document.getElementById("myId").style.minHeight = "1050px";
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
   
  };

  //Close full view
  handleCloseFullView = (type) => {
    this.setState({
      fullsize: false,
      exitfullsize: true,
      openFullView: false,
      closeFullView: true,
    });
    var element = document.getElementById("fullsize");
    document.getElementById("myId").style.minHeight = "350px";
    if (document.exitFullscreen) document.exitFullscreen();
  };

  render() {
    let detail = null;
    if (this.state.course) {
      detail = (
        <div>
          <div style={{ marginTop: "30px" }}>
            <div className="container">
              <div className="row">
                <div className="col-md-8" id="fullsize">
                   {console.log(this.state.file, "this.state")}
                   {(this.state.file.lesson_type == "quize"  &&  this.state.file.is_disabled==false) ? (
                    <Quizze lesson={this.state.file} 
                    sectionId={this.state.sectionId} 
                    courseId={this.course_id} />
                  ) : (
                    <>
                    {(this.state.file.lesson_type == "uploaddoc"  && this.state.file.is_disabled==false)? (
                     
                      <>
                      <div >
                        <div style={{ display: "flex",width:"100%",height:"100px",alignItems:"center",justifyContent:"center"}}>
                        <p style={{ fontSize:"16px",color:"#000" }}>Please click below button to download {this.state.file.name?this.state.file.name:this.state.filename} .</p>
                     
                        </div>
                           <div style={{ display: "flex",width:"100%",height:"60px",alignItems:"center",justifyContent:"center"}}>
                        {
                          this.state.file?.file_path ? (
                          <a className="btn btn-secondary"
                          style={{ fontSize:"16px",backgroundColor:"#002147",color:"#fff" }}
                           target="_blank" href={this.state.file?.file_path}>
                            <i className="fas fa-download"></i> Download</a> 
                          ):(<a target="_blank"
                          style={{ fontSize:"16px",backgroundColor:"#002147",color:"#fff" }}
                           className="btn btn-secondary"
                            href={this.state.file?.file_path}><i className="fas fa-download"></i> Download</a> 
                          )
                           }
                        </div>
                       </div>
                       
                         
                       </>
                       
                      
                    ) :(
                      <>
                      <div id="iframe-pdf"  ref={(ref) => (this.ifrme = ref)}>
                        {(this.state.file.lesson_type == "pdf"  && this.state.file.is_disabled==false) ? (
                          <>
                          <object type="application/pdf" 
                          data={`${this.state.file.file_path ||
                          this.state?.course.section[0]?.lesson[0]
                            ?.file_path}?#zoom=100`
                           
                          } 
                          id="myId" 
                          width="100%" 
                          height="350">
                          </object>
                          
                       </>
                        ):(  
                            <>
                            {(this.state.file.lesson_type == "video"  &&  this.state.file.is_disabled==false) ?(
                             <video
                             id="myId"
                             width="100%"
                             height={
                               !this.state.isFullScreen ? "437px" : "1000px"
                             }
                             controls
                             >
                             <source
                               id="myId"
                               src={
                                 this.state.file.file_path ||
                                 this.state?.course.section[0]?.lesson[0]
                                   ?.file_path
                               }
                               type="video/mp4"
                             />
                             {/* .replace('http:','https:') */}
                             <source src="mov_bbb.ogg" type="video/ogg" />
                             {utils.intlLang(
                               "Your browser does not support HTML video."
                             )}
                             </video>
                            ):( 
                              <>
                              {
                               this.state.file.is_disabled==false?( 
                                <div  id="myId" style={{height: "350px", overflowY: "scroll"}}>
                                <img src={ this.state.file.file_path ||
                                  this.state?.course.section[0]?.lesson[0]
                                  ?.file_path} style={{width:"100%"}}></img>
                                </div>):null
                              }
                             
                              </>
                                
                            )}
                            
                          
                          </>
                         )}
                        
                       
                      
                      </div>
                      <div style={{
                          display: this.state.showoptions ? "block" : "none",
                        }}>
                          <div
                        style={{
                          float: "right",
                          display: this.state.fullsize ? "block" : "none",
                        }}
                      >
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={this.handleexitFullView}
                        >
                          {utils.intlLang("Exit Theater View")}
                        </a>
                      </div>
                      <div
                        style={{
                          float: "right",
                          display: this.state.exitfullsize ? "block" : "none",
                        }}
                      >
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={this.handleFullView}
                        >
                          {utils.intlLang("Theater View")}
                        </a>
                      </div>

                      <div
                        style={{
                          float: "right",
                          display: this.state.closeFullView ? "block" : "none",
                        }}
                      >
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={ ()=>this.handleOpenFullView(this.state.file.lesson_type?this.state.file.lesson_type:this.state.lesson_type)}
                        >
                          {utils.intlLang("Full View")}
                        </a>{" "}
                        | &nbsp;
                      </div>
                      <div
                        style={{
                          float: "right",
                          display: this.state.openFullView ? "block" : "none",
                        }}
                      >
                        <a
                          style={{ cursor: "pointer", color: "#fff" }}
                          onClick={()=>this.handleCloseFullView(this.state.file.lesson_type?this.state.file.lesson_type:this.state.lesson_type)}
                        >
                          {utils.intlLang("Exit Full View")}
                        </a>
                      </div>
                      </div>
                      

                     </>
                    )}
                    </>
                  )}
                </div>

                <div
                  className="col-md-4"
                  style={{
                    borderLeft: "1px solid #E0E0E0",
                    "margin-left": "15px",
                    width: "30%",
                    display: this.state.exitfullsize ? "block" : "none",
                  }}
                >
                  <h4
                    className="panel-title"
                    style={{
                      padding: "15px 0px 0px 0px",
                      "font-weight": "600",
                      "margin-bottom": "10px",
                    }}
                  >
                    {utils.intlLang("List Of Course Section")}
                  </h4>
                  <hr />
                  <div className="sidebar">
                    <aside>
                      <div className="tab-info">
                        <div
                          className="tab-content tab-content-info"
                          style={{ marginTop: "12px" }}
                        >
                          {/* <!-- Single Tab --> */}
                          <div id="tab2" className="tab-pane fade active in">
                            <div className="info title">
                              {/* <!-- Start Course List --> */}
                              {this.state.course.section &&
                                this.state.course.section.map((el, i) => {
                                  if (!el.is_disabled) {
                                    return (
                                      <div className="course-list-items acd-items acd-arrow">
                                        <div
                                          className="panel-group symb"
                                          id="accordion"
                                        >
                                          <div className="panel panel-default">
                                            <div className="panel-heading">
                                              <h4 className="panel-title">
                                                <a
                                                  data-toggle="collapse"
                                                  data-parent="#accordion"
                                                  href={`#${i}`}
                                                  aria-expanded="false"
                                                  className="collapsed"
                                                >
                                                  {el.name}
                                                </a>
                                              </h4>
                                            </div>
                                            <div
                                              id={i}
                                              className="panel-collapse collapse"
                                              aria-expanded="false"
                                              style={{ height: " 0px" }}
                                            >
                                              <div className="panel-body">
                                                <ul>
                                                  {el.lesson.map((el2, i2) => {
                                                    if (!el2.is_disabled) {
                                                      return (
                                                        <li
                                                          key={el2._id}
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            this.handleViewedCourse(
                                                              el,
                                                              el2
                                                            );
                                                            if(el2.lesson_type=='uploaddoc')
                                                            {
                                                              this.setState({
                                                                closeFullView:false,
                                                                //exitfullsize:true
                                                              })
                                                            }else
                                                            {
                                                              this.setState({
                                                                closeFullView:true,
                                                                //exitfullsize:true
                                                              })
                                                            }
                                                            this.setState({
                                                              file: el2,
                                                              modal: true,
                                                              lesson_type:el2.lesson_type,
                                                              sectionId: el._id,
                                                              lessonChangeCounter:
                                                                this.state
                                                                  .lessonChangeCounter +
                                                                1,
                                                            });
                                                          }}
                                                        >
                                                          <div className="row" style={{width: "100%"}}>
                                                            <div className="item title col-xs-8">
                                                              <h5>
                                                                {el2.name}
                                                              </h5>
                                                            </div>
                                                            <div className="item info col-xs-4">
                                                              <span>
                                                                {el2.lesson_type ==
                                                                "video" ? (
                                                                  <i className="fas fa-video"></i>
                                                                ) : (
                                                                  <i className="fas fa-file-alt"></i>
                                                                )}{" "}
                                                                {
                                                                  el2.view_duration
                                                                }
                                                              </span>
                                                              <span>
                                                                {this.state.viewedLessonList.map(
                                                                  (vl) => {
                                                                    if (
                                                                      vl.lessonId ==
                                                                      el2._id
                                                                    ) {
                                                                      return (
                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "green",
                                                                          }}
                                                                          className="fas fa-check-circle"
                                                                        ></i>
                                                                      );
                                                                    }
                                                                  }
                                                                )}
                                                              </span>
                                                              <a></a>
                                                            </div>
                                                          </div>
                                                        </li>
                                                      );
                                                    }
                                                  })}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                              {/* <!-- End Course List --> */}
                            </div>
                          </div>

                          {/* <!-- End Single Tab --> */}
                        </div>
                        {/* <!-- End Tab Content --> */}
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <Helmet>
          <title>{this.state.course.name}</title>
          <meta name="keywords" content={this.state.course.meta_keywords} />
          <meta
            name="description"
            content={this.state.course.meta_description}
          />
        </Helmet>
        {detail}
      </>
    );
  }
}
