import React from "react";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import Banner from "../Banner/banner";
import CourseService from "../../services/courseService";
import userServices from "../../services/userService";
import CourseCategoryService from "../../services/courseCategoryService";
import BannerService from "../../services/bannerService";
import ReactHtmlParser from "react-html-parser";
import Testimonial from "../../services/testimonialService";
import Carousel from "react-bootstrap/Carousel";
import { Helmet } from "react-helmet";
import setting from "../../services/settingService";
import moment from "moment";
import { Modal, Button,Row, Col, ListGroup } from "react-bootstrap";
import LiveSessionCourse from "./LiveSessionCourse";
import LiveRecordingList from "./LiveRecordingList";
import Summary from "../userProfile/Summary";
import InternalCourse from "./InternalOnlineCourse";
import LiveSessionDisplayCourse from "./LiveSessionDisplayCourse";
import JoinMeetingModal from "./JoinMettingModal";
import TutorQuestion from "./TutorEvalquestion.json";
import Container from "react-bootstrap/Container";
import { CheckBoxweekDays } from "./CheckBoxweekDays";
import { ToastContainer, toast } from "react-toastify";
import Facebook from 'react-sharingbuttons/dist/buttons/Facebook'
import Twitter from 'react-sharingbuttons/dist/buttons/Twitter'
import utils from "../../utils/util";
import 'react-sharingbuttons/dist/main.css'
export default class PreviewCourse extends React.Component {
  constructor(props) {
    super(props);
    this.course_id = props.match.params.id;
    this.stud = window.user ? window.user.data._id : "";
    this.user = window.user;
    this.state = {
      course: "",
      courseCount: [],
      order: "",
      testimonialList: [],
      category: "",
      successMessage: false,
      errorMessage: false,
      settingList: [],
      modal: false,
      file: {},
       url : window.siteurl+'details/'+this.course_id,
       onlineSecret: null, onlineJoinUrl: null
      
     
    };
    this.search = { start: 0, perPage: 12, searchTxt: "", searchField: "" };
    this.userCurrentCourse = []
    this.bannerServ = new BannerService();
    this.courseServ = new CourseService();
    this.courseCatServ = new CourseCategoryService();
    this.testimonialServ = new Testimonial();
    this.userserv = new userServices();
    this.settingserv = new setting();

    this.weekDays = [
        { value: "sunday", name: "Sun" },
        { value: "monday", name: "Mon" },
        { value: "tuesday", name: "Tue" },
        { value: "wednesday", name: "Wed" },
        { value: "thrusday", name: "Thu" },
        { value: "friday", name: "Fri" },
        { value: "saturday", name: "Sat" },
      ];
    if (this.course_id) {
      this.courseServ.getPublicCourse(this.course_id).then(
        (response) => {

          this.categoriesOption = response.data.categories.map((el, i) => {
            return el.id.name;
          });

          this.setState({ 
            course: response.data,
            shareText:response.data.summary
           });
        },
        (error) => {
          alert(utils.intlLang("Opps, Something went wrong not able to fetch Course  details."));
        }
      );
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.course_id) {
      this.courseServ.getCourse(this.course_id, this.props.match.params.user).then(
        (response) => {
          this.setState({
            course: response.data,
          });
        },
        (error) => {
          alert(utils.intlLang("Oops, something went wrong not able to fetch course."));
        }
      );
    }
    this.getSettingList();
  }

  getSettingList() {
    this.settingserv.listSetting().then(
      (response) => {
        response.data.forEach((element) => {
          if (element.name === "online_session_URL") {
            this.setState({ onlineJoinUrl: element.value });
          }
        });
      },
      (error) => {
        alert(utils.intlLang("Oops, something went wrong not able to fetch Setting list."));
      }
    );
  }
  render() {
      console.log('props.match.params',this.props.match.params)
    const { course } = this.state;

    const liveCourseData = () => {
      let data;
      switch (course.time) {
        case "daily":
          data = (
            <>
              <h4 style={{ marginTop: "10px" }}>Live session start daily at : </h4>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{utils.intlLang("Start Date")}</th>
                    <th scope="col">{utils.intlLang("Start Time")}</th>
                    <th scope="col">{utils.intlLang("End Date")}</th>
                    <th scope="col">{utils.intlLang("End Time")}</th>
                  </tr>
                </thead>
                <tbody>
                  <td scope="col">1</td>
                  <td scope="col">{moment(course.start_date).format("ll")}</td>
                  <td scope="col"> {moment(course.start_time).format("hh:mm a")}</td>
                  <td scope="col">{moment(course.end_date).format("ll")}</td>
                  <td scope="col">{moment(course.end_time).format("hh:mm a")}</td>
                </tbody>
              </table>
            </>
          );
          break;
        case "weekly":
          data = (
            <>
              <h4 style={{ marginTop: "10px" }}>
                <div className="row">
                  <div style={{ padding: "18px" }} className="col-md-4">
                    {utils.intlLang("Live session start weekely on :")}{" "}
                  </div>
                  {this.weekDays.map((el, i) => (
                    <CheckBoxweekDays weekVal={course.weekDays} key={el.name} name="weekDays" value={el.name} />
                  ))}
                </div>
              </h4>

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{utils.intlLang("Start Date")}</th>
                    <th scope="col">{utils.intlLang("Start Time")}</th>
                    <th scope="col">{utils.intlLang("End Date")}</th>
                    <th scope="col">{utils.intlLang("End Time")}</th>
                  </tr>
                </thead>
                <tbody>
                  <td scope="col">1</td>
                  <td scope="col">{moment(course.start_date).format("ll")}</td>
                  <td scope="col"> {moment(course.start_time).format("hh:mm a")}</td>
                  <td scope="col">{moment(course.end_date).format("ll")}</td>
                  <td scope="col">{moment(course.end_time).format("hh:mm a")}</td>
                </tbody>
              </table>
            </>
          );
          break;
        case "custom":
          data = (
            <>
              <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>{utils.intlLang("Live session start on these following dates:")}</h4>

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{utils.intlLang("Start Date")}</th>
                    <th scope="col">{utils.intlLang("Start Time")}</th>
                    <th scope="col">{utils.intlLang("End Date")}</th>
                    <th scope="col">{utils.intlLang("End Time")}</th>
                  </tr>
                </thead>
                <tbody>
                  <td scope="col">1</td>
                  <td scope="col">{moment(course.start_date).format("ll")}</td>
                  <td scope="col"> {moment(course.start_time).format("hh:mm a")}</td>
                  <td scope="col">{moment(course.end_date).format("ll")}</td>
                  <td scope="col">{moment(course.end_time).format("hh:mm a")}</td>
                </tbody>
              </table>
              <ListGroup style={{ fontSize: "14px", color: "#002147" }}>
                <Row>
                  {course.selectedDays.map((el, i) => {
                    return (
                      <Col md={3} key={i}>
                        <ListGroup.Item>{moment(el).format("ll")}</ListGroup.Item>
                      </Col>
                    );
                  })}
                </Row>
              </ListGroup>
            </>
          );
          break;

        default:
          data = <div>{utils.intlLang("loading")}</div>;
      }
      return data;
    };
    let coursedata;
    if (this.state.course) {
      if (this.state.course.end_date===null && this.state.course.section.length===0) {
        coursedata = (
          <Container style={{ minHeight: "600px", marginTop: "10px" }}>
            
                <div className="feedback-internal">
                  <Link
                      className="crs-btn"
                      to={
                        `/testimonial/${this.course_id}`
                      }
                    >
                        {utils.intlLang("Add testimonial")}
                    </Link>
                </div>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{utils.intlLang("Name")}</th>
                  <th scope="col">{utils.intlLang("Download")}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.course.offline_course_content.map((el, i) => {
                  return (
                    <tr>
                      <th scope="row">{i + 1}</th>
                      <td>{el.split("$").slice(1).join()}</td>
                      <td>
                        <a target="_blank" href={el}>
                          {utils.intlLang("Download")}
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Container>
        );
      } else if (this.state.course.course_type === "online") {
        coursedata = (
          <>
            <Iframe
              url={
                this.state.course.view_course_content +
                `?endpoint=${window.apiurl}/xapi&auth=Basic ${window.user.token}&actor={"name":["${window.user.data._id}"],"id":["${window.user.data._id}"],"mbox":["mailto:${window.user.data.email}"]}&activity_id=http://${this.course_id}`
              }
              width="100%"
              height="550px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"
              allowFullScreeen={true}
              target="_blank"
              controls
            />
            <div className="feedback-tincan">
              <Link
                to={{
                  pathname: `/feedback/${this.course_id}`,
                  state: {
                    questions: TutorQuestion,
                    title: "Tutor Evaluation Form",
                    type: "tutor_evaluation",
                    teacherId: this.state.course.created_by._id,
                  },
                }}
              >
                {utils.intlLang("Feedback")}
              </Link>
            </div>
          </>
        );
      } else if (this.state.course.start_date!==null && this.state.course.end_date!==null) {
        coursedata = (
          <>
            <Container style={{ minHeight: "600px", marginTop: "10px" }}>
              <div className="row crs-name">
                <div className="col-sm-6 col-xs-6 name">
                  
                  <div className="coursetitle">{course.name}</div>
                </div>
                <div className="col-sm-6 col-xs-6">
                <div className="feedback-internal">
                  <Link
                     className="crs-btn"
                    to={
                      `/testimonial/${this.course_id}`
                    }
                  >
                    {utils.intlLang("Add testimonial")}
                  </Link>
                </div>
              </div>
              </div>
              {course.live_sesson_file_content.length ? (
                <>
                  <h4 style={{ marginTop: "10px" }}>{utils.intlLang("Course Files:")} </h4>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{utils.intlLang("Name")}</th>
                        <th scope="col">{utils.intlLang("Download")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.course.live_sesson_file_content.map((el, i) => {
                        return (
                          <tr>
                            <th scope="row">{i + 1}</th>
                            <td>{el.split("$").slice(1).join()}</td>
                            <td>
                              <a target="_blank" href={el}>
                              {utils.intlLang("Download")}
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>{" "}
                </>
              ) : null}
               <LiveSessionDisplayCourse {...this.props} />;
              <div className="" style={{ marginBottom: "13px", marginTop: "13px", textAlign: "center" }}>
                <JoinMeetingModal courseId={this.state.course._id} studentName={window.user?.data?.first_name} />
                             
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <LiveSessionCourse
                      displayAccordian = {true}
                      startDate={course.start_date}
                      endDate={course.end_date}
                      startTime={course.start_time}
                      endTime={course.end_time}
                      type={course.time}
                      weekVal={course.weekDays}
                      selectedDays={course.selectedDays}
                    />
                  </div>
                  <div className="col-md-6">
                    <Summary summary={course.summary} />
                  </div>
                </div>
              </div>
              <LiveRecordingList courseId={this.state.course._id} crsName={this.state.course.name} />
             
            </Container>
          </>
        );
      } else if (this.state.course.end_date===null && this.state.course.section.length >0) {
        return <InternalCourse {...this.props} />;
      } else {
        coursedata = (
          <div className="course-body">
            <strong>{utils.intlLang("Your Course Will Be Avaliable Soon.")}</strong>
          </div>
        );
      }
    }
    return (
      (
        <>
          {coursedata} <ToastContainer />
        </>
      ) || <></>
    );
  }
}

