import React from "react";
import ReactParser from "react-html-parser";
import utils from "../../utils/util";
export default class Summary extends React.Component {
  render() {
    const { summary } = this.props;
    return (
      <div>
        <div style={{ marginBottom: "50px" }}>
          <aside>
            <div class="tab-info">
              <div class="tab-content tab-content-info">
                {/* <!-- Single Tab --> */}
                <div id="tab1" class="tab-pane fade active in">
                  <div class="info title">
                    {/* <!-- Start Course List --> */}
                    <div class="course-list-items acd-items acd-arrow">
                      <div class="panel-group symb" id="accordionn">
                        <div class="panel panel-default">
                          <div class="panel-headings">
                            {/* <h4 class="panel-title">
                              <a
                                style={{ border: "1px solid #ccc", paddingLeft: "10px" }}
                                data-toggle="collapse"
                                data-parent="#accordionn"
                                href={`#summary${2}`}
                                aria-expanded="false"
                                class="collapsed"
                              >
                                {utils.intlLang("Summary")}
                              </a>
                            </h4> */}
                          </div>
                          <div
                          // id={"summary2"}
                          // class="panel-collapse collapse"
                          // aria-expanded="false"
                          // style={{ height: " 0px" }}
                          >
                            <div class="panel-body">
                              <ul>
                                <li>
                                  <div
                                    class="item name"
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    {ReactParser(summary)}
                                  </div>
                                  <div class="item title"></div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- End Course List --> */}
                  </div>
                </div>

                {/* <!-- End Single Tab --> */}
              </div>
              {/* <!-- End Tab Content --> */}
            </div>
          </aside>
        </div>
      </div>
    );
  }
}
