import React from 'react';
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import LikesCount from '../../services/likeService';
import CourseService from "../../services/courseService";
import BannerService from "../../services/bannerService";
import setting from "../../services/settingService";
import Tooltip from "react-bootstrap/Tooltip";
// import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
export default class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = { settingList: [] }
        this.likesServ = new LikesCount();
        this.settingserv = new setting();
    }
    componentDidMount() {
        this.getSettingVal();
    }
    getSettingVal() {
        this.settingserv.listSetting(0, 10000000).then(
            (response) => {
                this.setState({ settingList: response.data });
            },
            (error) => {
                this.setState({ settingList: [] });
            }
        )
    }
    render() {
        let currencyVal = "";
        for (let i = 0; i < this.state.settingList.length; i++) {
            if (this.state.settingList[i].name === 'currencySymbol') {
                currencyVal = this.state.settingList[i].value;
            }
        }
        return (
            <Link to={{ pathname: "/details/" + this.props.id }}>
             <div className="popular-courses-items bottom-price">
                <div className="col-md-4 col-sm-6 equal-height">
                    <div className="item">
                        <Link to={{ pathname: "/details/" + this.props.id }}>
                            <div className="thumb">

                                {this.props.detail}

                                <div className="overlay">
                                    {this.props.enroll}
                                </div>
                            </div>
                        </Link>
                        <div className="info">
                            <div className="author-info">
                                <div className="thumb">
                                    <Link to={{ pathname: "/details/" + this.props.id }}>{this.props.userImage}</Link>
                                </div>
                                <div className="others">
                                    <Link to={{ pathname: "/details/" + this.props.id }}>{this.props.firstName}</Link>
                                    <div className="rating">

                                        <span onClick={() => this.props.countLikesFnc(this.props.id)}><i className="far fa-thumbs-up fa-2x"></i></span>


                                        {this.props.likescnt}
                                        {/* <Link to={{ pathname: "/details/" + this.props.id }}>
                                            <div className="price-btn"><>{currencyVal}{" "}</>
                                                {this.props.coursePrice}
                                            </div>
                                        </Link> */}
                                    </div>
                                </div>
                            </div>
                            <Link to={{ pathname: "/details/" + this.props.id }}>
                                <h4><Link to={{ pathname: "/details/" + this.props.id }}>{this.props.courseName}</Link></h4>
                                <div className="summary-descr">
                                <OverlayTrigger
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                    <Tooltip {...props}>
                                        {this.props.description}
                                    </Tooltip>
                                    )}
                                    placement="bottom"
                                >
                                    {/* <Button variant="warning">Tooltip Button</Button> */}
                                    <Link to={{ pathname: "/details/" + this.props.id }}>{ReactHtmlParser(this.props.description_short)}</Link>
                                </OverlayTrigger>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
             </div>
            </Link>
        )
    }
}