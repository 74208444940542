import React from "react";
import CourseService from "../../services/courseService";
import CourseCategoryService from "../../services/courseCategoryService";
import { Link } from "react-router-dom";
import utils from '../../utils/util';
export default class TopCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = { courseCategoryList: [], totalCount: 0, courseCount: [] };
    this.courseCatServ = new CourseCategoryService();
    this.courseServ = new CourseService();
  }
  componentDidMount() {
    this.getCourseCategoryList();
    this.getCourseCount();
  }
  getCourseCategoryList() {
    this.courseCatServ.courseCategoryFrontend().then(
      (response) => {
        this.setState({ courseCategoryList: response.data });
      },
      (error) => {
        this.setState({ courseCategoryList: [] });
      }
    );
  }

  getCourseCount() {
    this.courseServ.totalCourseCount().then(
      (response) => {
        let arr = response.data.map(([key, value]) => {
          let name = key.split("/")[0];
          let _id = key.split("/")[1];
          let count = value;
          return {
            name,
            _id,
            count,
          };
        });
        this.setState({ courseCount: arr });
      },
      (error) => {
        this.setState({ courseCount: [] });
      }
    );
  }

  render() {
    let color = ["java", "mariner", "malachite", "brilliantrose", "brilliantrose"];
    return (
      <>
        <div className="top-cat-area inc-trending-courses about-area default-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 top-cat-items text-light inc-bg-color text-center">
                <div className="col">
                  {this.state.courseCount.map((el, i) => {
                    return (
                      <div className="col-md-4 col-sm-6 equal-height">
                        <div className={`item ${color[i]} bg${i + 1}`}>
                          <Link to={{ pathname: "/course/category/" + el._id }}>
                            <i className="flaticon-feature"></i>
                            <div className="info categoryname">
                              <h4>{el.name.length >= 60 ? el.name.substring(0, 56) + "..." : el.name}</h4>
                              <span className="count-course">({el.count}) {utils.intlLang("Courses")}</span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
