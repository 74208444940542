import React from "react";
import Testimonial from "../../services/testimonialService";
import utils from '../../utils/util';
export default class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.search = { start: 0, perPage: 12, searchTxt: "", searchField: "" };
    this.state = { TestimonialList: [] };

    this.testimonialServ = new Testimonial();
  }
  componentDidMount() {
    this.getTestimonialList();
  }
  createOwlCarousel() {
    setTimeout(() => {
      window.jQuery('.clients-review-carousel').owlCarousel({
        loop: false,
        margin: 30,
        nav: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>"
        ],
        dots: true,
        autoplay: true,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 2
          },
          1000: {
            items: 3
          }
        }
      });
    }, 600);
  }
  getTestimonialList() {
    this.testimonialServ.listTestimonial(
      this.search.start, this.search.perPage,
      this.search.searchTxt, this.search.searchField
    )
      .then(
        (response) => {
          this.testimonialList = [];
          for (let i = 0; i < response.data.length; i++) {
            this.testimonialList.push(response.data[i]);
          }

          this.setState({ TestimonialList: response.data });
          this.createOwlCarousel();
        },
        (error) => {
          this.setState({ TestimonialList: [] });
        }
      );
  }
  render() {
    let data = [];
    if (this.state.TestimonialList) {
      for (let i = 0; i < this.state.TestimonialList.length; i++) {
        if (this.state.TestimonialList[i].content.length >= 210) {
          this.state.TestimonialList[i].content =
            this.state.TestimonialList[i].content.substring(0, 210) + "...";
        }
        data.push(
          <div key={"divcol-" + i} className="infotest">
            <div className="item">
              <div className="col-md-12 info">
                <p className="widthsize">{this.state.TestimonialList[i].content}</p>
                <div className="testusername">
                  <h4>{this.state.TestimonialList[i].name}</h4>
                </div>
                <div className="testcrsname">
                  <span>{this.state.TestimonialList[i].course?this.state.TestimonialList[i].course.name:''}</span>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
    return (
      <div className="testimonials-area carousel-shadow default-padding bg-dark text-light">
        <div className="container">
          <div className="row">
            <div className="site-heading text-center">
              <div className="col-md-8 col-md-offset-2">
                <div className="linehide">
                  <h2>{utils.intlLang("Students Feedback")}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="testmargin">
              <div className="col-md-12">
                <div className="clients-review-carousel owl-carousel owl-theme">
                  {data}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}