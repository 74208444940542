import React from 'react';
import Banner from '../Banner/banner';
import News from '../../pages/blogs/news';
import Events from '../../pages/blogs/event';
import Announcement from '../../pages/blogs/announcement';
import utils from '../../utils/util'
import { Link } from "react-router-dom";
export default class Blogs extends React.Component {

    constructor(props) {
        super(props);
    }
    getComponent(pathname) {
      console.log(pathname,'pathname')
      switch (pathname) {
        case "/blogs":   return <News  {...this.props}/>;
        case "/events":   return <Events  {...this.props}/>;
        case "/announcements": return <Announcement  {...this.props}/>;
        default:      return <News  {...this.props}/>
      }
    }
    render() {
        return (
          <>
            <Banner
              pagename={"News Announcements And Events"}
              pagelink={"/blogs"}
              title={"News Announcements And Events"}
              showSearch={false}
            />
            <div className="blog-area full-blog standard full-blog">
              <div className="container">
                <div className="row">
                  <div className="blog-items">
                    <div className="blog-content col-md-10 col-md-offset-1">
                      <div className="single-item">
                        <div className="students-profiel adviros-details-area default-padding">
                          <div className="col-md-15 info main-content">
                            <div className="tab-info">
                            <ul class="nav nav-pills">
                                  <li 
                                  className={this.props.location.pathname==='/blogs' ? "active" :""}
                                   >
                                  <Link to= {{ pathname: "/blogs"}}>
                                      {utils.intlLang("News")}
                                  </Link>

                                </li>
                                  <li
                                  className={this.props.location.pathname==='/events' ? "active" :""}
                                  >
                                  <Link to= {{ pathname: "/events"}}>
                                      {utils.intlLang("Events")}
                                  </Link>

                                  </li>
                                  <li
                                  className={this.props.location.pathname==='/announcements' ? "active" :""}
                                  >
                                  <Link to= {{ pathname: "/announcements"}}>
                                      {utils.intlLang("Announcements")}
                                  </Link>
                                 </li>
                                    </ul>
                     
                              {
                              this.props.location.pathname==='/blogs' ? 
                               ( <News />):
                               (this.getComponent(this.props.location.pathname))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
}



