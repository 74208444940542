import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import BlogService from "../../services/blogService";
import moment from 'moment';
import utils from '../../utils/util'

export default class Blogs extends React.Component {

    constructor(props) {
        super(props);
        this.search = { start: 0, perPage: 4, searchTxt: "", searchField: "", type: "event" };
        this.state = {
            blogList: [], totalCount: 0, scrollPosition: 400
        };
        this.blogServ = new BlogService();
    }

    componentDidMount() {
        this.getBlogList();
    }

    searchBlogList(searchFilters) {
        this.search.searchTxt = searchFilters.term;
        this.search.searchField = searchFilters.fieldname;
        this.search.start = 0;
        this.getBlogList();
    }

    scrollUp(amountToScroll) {
        this.setState({
            scrollPosition: this.state.scrollPosition + amountToScroll
        })
        window.scrollTo(0, this.state.scrollPosition)
    }

    getBlogList() {
        this.blogServ.listBlog(
            this.search.start, this.search.perPage, this.search.type,

        )
            .then(
                (response) => {
                    this.setState({ blogList: response.data, totalCount: response.total });
                },
                (error) => {
                    this.setState({ blogList: [], totalcount: 0 });
                }
            );
    }
    handlePaging(e) {
        if (e.target.text) {
            this.search.start = ((parseInt(e.target.text)) * this.search.perPage) - this.search.perPage;
            this.getBlogList();
        }
    }

    render() {
        let active = Math.ceil((this.search.start + 1) / this.search.perPage);
        let pages = Math.ceil(this.state.totalCount / this.search.perPage);
        let items = [];
        for (let number = 1; number <= pages; number++) {
            items.push(
                <Pagination.Item key={number} onClick={this.handlePaging.bind(this)} active={number === active}>
                    {number}
                </Pagination.Item>,
            );
        }
        let data = [];
        if (this.state.blogList) {
            for (let i = 0; i < this.state.blogList.length; i++) {
                if (this.state.blogList[i].description.length >= 142) {
                    this.state.blogList[i].description = this.state.blogList[i].description.substring(0, 142);
                }
                data.push(

                    <div className="news" key={'event-' + i}>
                        <h2 className="title">
                            {this.state.blogList[i].title}<span> - {moment(this.state.blogList[i].createdAt).format('DD/MM/YYYY')}</span>
                        </h2>
                        <div className="snippet">
                            {ReactHtmlParser(this.state.blogList[i].description)}
                        </div>
                        <div className="readMore">
                            <Link to={{ pathname: "/blog/" + this.state.blogList[i]._id }}>{utils.intlLang("Read More")}</Link>

                        </div>
                        <div className="clear"></div>
                    </div>

                )
            }
        }
        return (
            <>

                {data}
                <Pagination className="page" size="sm" onClick={this.scrollUp.bind(this, 30)}>{items}</Pagination>

            </>
        )
    }
}



