import React from "react";
import ReactParser from "react-html-parser";
import utils from '../../utils/util';
import { Link } from "react-router-dom";
import CourseService from "../../services/courseService";
import BackButton from "../../components/BackButton";
import Container from "react-bootstrap/Container";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik, ErrorMessage } from "formik";
import { ToastContainer, toast } from "react-toastify";
import Iframe from "react-iframe";
import Moment from 'moment';
import "react-toastify/dist/ReactToastify.css";
export default class AssignmentDetail extends React.Component {
    constructor(props) {
        super(props);
        this.courseid= props.match.params.courseid;
        this.assignmentid= props.match.params.assignmentid;
        this.assignmentCreatedBy= '';
        this.courseServ = new CourseService();
        this.state = { 
          assignmentdetail:[],
          is_reuploadable:false,
          uploadedStatus:false,
          uploadedImg:'',
          assignmentStatus:false,
          assignmentEnd:false,
          course:[]
        };
        this.state.assignment =
         {
            image: "" ,
            file_ext: ''
         };

            this.schema = Yup.object({
              image: Yup.mixed().required('A file is required')
              .test('fileFormat', 'Only the following formats are accepted: .jpeg, .jpg,.png,.pdf', (value) => {
                return value && (
                   ['image/jpeg'].includes(value.type) 
                   || ['image/jpg'].includes(value.type)
                   || ['image/png'].includes(value.type)
                   || ['application/pdf'].includes(value.type)
                   );
              }),
                }); 

      }
componentDidMount() {
    if (this.assignmentid) {
        this.courseServ.getAssignment(this.assignmentid).then(
          (response) => {
              this.assignmentCreatedBy=response.data.created_by._id
              let filename=[]
              response.data.image.map(img=>{
                let file=img.split('$')
                filename.push(file[file.length - 1])
              })
              response.data.filename=filename
              if(new Date(response.data.end_time).getTime() < new Date().getTime())
              {
                this.setState({assignmentEnd:true})
              }
            this.setState({
                assignmentdetail:response.data,
            });
          },
          (error) => {
            alert(utils.intlLang("Oops, something went wrong not able to fetch course."));
          }
        );
        if (this.courseid) {
          this.courseServ.getCourse(this.courseid, this.props.match.params.user).then(
            (response) => {
              this.setState({
                course: response.data,
              });
            },
            (error) => {
              alert(utils.intlLang("Oops, something went wrong not able to fetch course."));
            }
          );
        }
        var obj={
          assignmentId:this.assignmentid,
          userId:window.user.data._id,
          couresId:this.courseid,
        }
        this.courseServ.getAssignmentStatus(obj).then(
          (response) => {
              if(response.total>0)
              {
                if(response.data[0].is_reuploadable)
                {
                  this.setState({
                    assignmentStatus:false,
                    uploadedStatus:false,
                    is_reuploadable:response.data[0].is_reuploadable,
                    uploadedImg:response.data[0].image
                  });
                }
                else
                {
                  this.setState({
                    assignmentStatus:true,
                    is_reuploadable:response.data[0].is_reuploadable,
                    uploadedStatus:response.data[0].is_reuploadable,
                    uploadedImg:response.data[0].image
                  });
                }
               
              }
            
          },
          (error) => {
            alert(utils.intlLang("Oops, something went wrong not able to fetch course."));
          }
        );
      }
}
handleUpload =(e)=>{
  
    this.setState({ uploadedStatus: !this.state.uploadedStatus });
}
submitForm(values, actions) {
    actions.setSubmitting(false);
    console.log('values',values)
    values.userId=window.user.data._id
    values.couresId=this.courseid
    values.assignmentId=this.assignmentid
    values.assignmentCreatedBy=this.assignmentCreatedBy
    this.courseServ.uploadAssignment(values).then(
        (response) => {
            console.log(response.data.data.image,'responsedata')
           toast.success("File SuccessFully uploaded.");
           this.setState({ uploadedStatus: !this.state.uploadedStatus,
            uploadedImg:response.data.data.image,
            assignmentStatus:true
          });
           
        },
        (error) => {
          this.setState({ redirect: false });
        }
      );
  }
  render() {
   
    console.log(this.state,'course')
    return (
      <div>
        <div style={{ marginBottom: "0px" }}>
        <Container style={{ minHeight: "600px", marginTop: "10px" }}>
       
               
          <div class="container">
         
            <div class="row crs-name">
              <div class="col-sm-6 col-xs-6 name">
              <Link
            className="crs-btn"
            style={{ textDecoration: "underline", 
            cursor: "pointer", display: "inline-flex", 
            color:'#ffffff' }}
            to={
                `/user/course/${this.courseid}/${window.user.data.first_name}`
            }
            >
              &lt; Back
            </Link>
                <div class="coursetitle">{this.state.course.name}</div>
                </div><div class="col-sm-6 col-xs-6">
                  <div class="feedback-internal">
            </div></div></div>
            <h2></h2>
            <div class="adviros-details-area default-padding" style={{ paddingTop:"15px" }}>
            <div class="row">
                <div class="col-md-5 thumb">

                <img src={this.state.course.image} alt="Thumb" />
                     <div class="desc">
                        <h4>{this.state.assignmentdetail.title}</h4>
                     
                    </div>
                </div>
                <div class="col-md-7 info main-content">
                    
                    <div class="tab-info">
                       
                        <ul class="nav nav-pills">
                            <li class="active">
                                <a data-toggle="tab" href="#tab1" aria-expanded="true">
                                Instructions
                                </a>
                            </li>
                            <li class="">
                                <a data-toggle="tab" href="#tab2" aria-expanded="false">
                                Description
                                </a>
                            </li>
                            <li class="">
                                <a data-toggle="tab" href="#tab3" aria-expanded="false">
                                   Attachment
                                </a>
                            </li>
                        </ul>
                      
                        <div class="tab-content tab-content-info">
                          
                            <div id="tab1" className="tab-pane fade active in">
                                <div class="info title">
                                {
                                  this.state.assignmentdetail.instructions?ReactParser(this.state.assignmentdetail.instructions):''
                                }
                                </div>
                            </div>
                           
                            <div id="tab2" className="tab-pane fade">
                                <div class="info title">
                                {
                                this.state.assignmentdetail.description?ReactParser(this.state.assignmentdetail.description):''
                                }
                                </div>
                            </div>
                           
                            <div id="tab3" className="tab-pane">
                                <div class="info title">
                                  <div className="row">
                                  <div className="col-md-8">
                                    {
                                      //console.log(this.state.assignmentdetail.image,'zzz')
                                      this.state.assignmentdetail.image ? (
                                      this.state.assignmentdetail.image.map((img,index)=>{
                                        return(<div><a href={img} target="_blank">View Attachment({this.state.assignmentdetail.filename[index]})</a></div>)
                                      })
                                      ) : ""
                                      
                                    }
                                  
                                    
                                  </div>
                                  <div className="col-md-4">
                                    {
                                      this.state.is_reuploadable=== true? ( <div  
                                     >
                                      <div className="col-md-12">
                                     {this.state.uploadedImg?(<a target="_blank" href={this.state.uploadedImg}>
                                             View Uploaded File
                                             </a>):""} </div>
                                     </div>) :( <div  
                                        className={this.state.assignmentStatus === true ? "show":"hide"}
                                        style={{display:this.state.assignmentStatus===true?"block":"none" }}>
                                      <div className="col-md-12">
                                     {this.state.uploadedImg?(<a target="_blank" href={this.state.uploadedImg}>
                                             View Uploaded File
                                             </a>):""}
                                    </div>
                                  </div>)
                                    }
                                 
                                  </div>
                                  </div>
                                 
                                </div>
                                <div  className={this.state.assignmentStatus === true ? "hide":"show"}>
                                  <div>
                                    {this.state.assignmentEnd===false ? (<a 
                                       onClick={this.handleUpload} 
                                       style={{    cursor: "pointer"  }}
                                       
                                       >
                                        Upload Assignment
                                    </a>) :(<p style={{ color: "red" }}>Assignment completion date has been expired.</p>) }
                                  </div>
                                    
                                </div>
                                <div 
                                class={this.state.uploadedStatus=== true ? "show":"hide"}
                                style={{ marginTop: "20px" }}
                                >
                                   <Formik
        validationSchema={this.schema}
        initialValues={{ ...this.state.assignment, checkimg: false }}
        enableReinitialize={true}
        onSubmit={this.submitForm.bind(this)}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <div className="address addresslist">
            <Container>
              <Form onSubmit={handleSubmit}>
               
                <Row>
             
                  <Col sm={6} md={6}>
                    <Form.Group>
                      <p>File*</p>
                      <input
                        type="file"
                        name="image"
                        onChange={(e) => {
                          let url = e.target.files[0];
                          setFieldValue("checkimg", true);
                          setFieldValue("image", url);
                          let file_url = URL.createObjectURL(url);
                          setFieldValue("file_url", file_url);
                          setFieldValue("file_ext", url.type);
                          
                        }}
                      />
                      
                    </Form.Group>
                    <ErrorMessage name="image">
                      {(msg) => <div className="err_below">{msg}</div>}
                    </ErrorMessage>
                  </Col>
                </Row>
              
                <Row>
                  
                  <Col sm={12} md={4}>
                    <button
                      className="btn btn-lg btn-primary btn-block setbgcolor"
                      type="submit"
                    >
                      Save
                    </button>
                    
                  </Col>
                 
                </Row>
              </Form>
            </Container>
           
          </div>
        )}
      /> </div>
                       
                                

                                <ToastContainer />
                            </div>
                            
                        </div>
                       
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
           
                     
                      
        </Container>
        </div>
        
      </div>
    );
  }
}
