import React, { Component } from "react";
import { Formik, ErrorMessage } from "formik";
import moment from 'moment';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import utils from '../../utils/util';
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UserService from "../../services/userService";
import Iframe from "react-iframe";
import countryList from "../../components/countryList.json";
import CountryCode from '../../components/countryCode.json';
const axios = require("axios");
export default class UserForm extends Component {
  constructor(props) {
    super(props);
    this.passwordref = null;
    this.user_id = window.user.data._id;
    this.image = "";
    this.state = {
      totalCount: 0,
      file: null,
      redirect: false,
      loading: false,
      scrollPosition: 400,
      sucessMessage: "",
      errorMessage: "",
      profileImg: "",
      orderList: [],
      userCourse: [],
      likescount: [],
      notificationList: [],
      settingList: [],
      student: {
        title: "Mr",
        first_name: "",
        last_name: "",
        email: "",
        address_1: "",
        address_2: "",
        address_3: "",
        town: "",
        state: "",
        country: "",
        city: "",
        zipcode: "",
        phone: "",
        recieve_newsletter: false,
        receive_personalised: false,
        is_active: false,
        courses: [],
        roles: [],
        password: "",
        newPassword: "",
        verifyPassword: "",
        dob: "",
        gender: "",
        race: "",
        ethnicity: "",
        marital_status: "",
        income: "",
        education: "",
        employment: "",
        industry: "",
        countryDialCode:"",
      },
    };
    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)?$/;
    this.schema = Yup.object({
      title: Yup.string(),
      first_name: Yup.string().required(utils.intlLang("first name is a required field")),
      last_name: Yup.string().required(utils.intlLang("last name is a required field")),
      email: Yup.string().required(utils.intlLang("email is a required field")).email(),
      address_1: Yup.string().required(utils.intlLang("address 1 is a required field")),
      address_2: Yup.string(),
      address_3: Yup.string(),
      country: Yup.string().required(utils.intlLang("country is a required field")),
      state: Yup.string().required(utils.intlLang("state is a required field")),
      city: Yup.string().required(utils.intlLang("city is a required field")),
      passsword: Yup.string(),
      zipcode: Yup.string().required(utils.intlLang("zipcode is a required field")),
      phone: Yup.string()
      .required(utils.intlLang("phone number is a required field"))
      .matches(phoneRegExp, utils.intlLang("invalid phone number")),
      countryDialCode: Yup.string().required(utils.intlLang('Required')),
      town: Yup.string(),
      // dob: Yup.date(),
      gender: Yup.string(),
      race: Yup.string(),
      ethnicity: Yup.string(),
      marital_status: Yup.string(),
      income: Yup.string(),
      education: Yup.string(),
      employment: Yup.string(),
      industry: Yup.string(),
    });

    this.userServ = new UserService();
  }
  submitForm(values, actions) {
    const formData = new FormData();
    if (values.user_image) {
      formData.append("image", values.image);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(window.apiurl + "/user/upload/file", formData, config)
        .then((response) => {
          delete values.user_image; //deleting user blob url
          values.image = response.data.originalname;
          this.submitStudentForm(values, actions);
        })
        .catch((error) => {});
    } else {
      this.submitStudentForm(values, actions);
    }
  }
  submitStudentForm(values, actions) {
    // let dial_Code = values.countryDialCode + " " + values.phone; 
    // values.phone = dial_Code;
    // console.log(values, 'Valuesssssssss')
    if (this.user_id) {
      this.userServ.editUser(values).then(
        (response) => {
          actions.setSubmitting(false);
          toast.success("profile updated");
          window.scroll(30, 60);
          // this.props.handleUserName(response);

        },
        (error) => {
          actions.setSubmitting(false);
          toast.error(error.message);
        }
      );
    }
  }

  render() {
    console.log(this.props.student,'this.props.student');
    console.log(window.user.data,'window.user.data');

    let country_dial_code = [];
    for (let i = 0; i < CountryCode.length; i++) {
      country_dial_code.push(
        <option key={CountryCode[i].code + " " + i} value={CountryCode[i].dial_code }>
          {CountryCode[i].dial_code } {" "} {CountryCode[i].name}
        </option>
      );
    }

    let countryOption = [];
    for (let i = 0; i < countryList.length; i++) {
      countryOption.push(
        <option key={countryList[i].Code + " " + i} value={countryList[i].Name}>
          {countryList[i].Name}
        </option>
      );
    }

    return (
      <div>
        <Formik
          validationSchema={this.schema}
          // initialValues={window.user.data || this.props.student}
          initialValues={this.props.student}
          enableReinitialize={true}
          onSubmit={this.submitForm.bind(this)}
          render={({
            values,
            errors,
            status,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <div className="address addresslist">
              {
                // console.log(values.dob.split("").splice(0, 10).join(""),'values.dob')
                console.log(values, "values.dob")
              }
              <Container>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col sm={9} md={6}>
                      <Form.Label>{utils.intlLang("Title*")}</Form.Label>
                      <Form.Control
                        as="select"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        isValid={touched.title && !errors.title}
                      >
                        <option value="Dr">{utils.intlLang("Dr")}</option>
                        <option value="Mr">{utils.intlLang("Mr")}</option>
                        <option value="Mrs">{utils.intlLang("Mrs")}</option>
                        <option value="Miss">{utils.intlLang("Miss")}</option>
                        <option value="Ms">{utils.intlLang("Ms")}</option>
                      </Form.Control>
                    </Col>
                    <Col sm={6} md={2}>
                      <div className="imageUpload">
                        <Form.Group>
                          <p>{utils.intlLang("Update Profile Image")}</p>
                          <input
                            type="file"
                            name="image"
                            onChange={(e) => {
                              setFieldValue("image", e.target.files[0]);
                              var url = URL.createObjectURL(e.target.files[0]);
                              setFieldValue("user_image", url);
                            }}
                          />
                        </Form.Group>
                      </div>
                    </Col>
                    <Col sm={6} md={4}>
                      <img
                        src={values.user_image || values.image}
                        alt="user"
                        height="60px"
                        width="60px"
                        style={{ borderRadius: "50%" }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>{utils.intlLang("First Name*")}</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.first_name}
                          name="first_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.first_name && !errors.first_name}
                        />
                        <ErrorMessage name="first_name">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>{utils.intlLang("Last Name*")}</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.last_name}
                          name="last_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.last_name && !errors.last_name}
                        />
                        <ErrorMessage name="last_name">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>
                          {utils.intlLang("Address Line 1*")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.address_1}
                          name="address_1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="address_1">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>
                          {utils.intlLang("Address Line 2")}{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.address_2}
                          name="address_2"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="address_2">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>
                          {utils.intlLang("Address Line 3")}{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.address_3}
                          name="address_3"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.address_3 && !errors.address_3}
                        />
                        <ErrorMessage name="address_3">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>{utils.intlLang("City*")}</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.city}
                          name="city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.city && !errors.city}
                        />
                        <ErrorMessage name="city">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Group>
                        <Form.Label>
                          {utils.intlLang("Date Of Birth")}
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={values.dob ? values.dob.split("").splice(0, 10).join("") : values.dob}
                          name="dob"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.dob && !errors.dob}
                        />
                        {/* <ErrorMessage name="dob">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage> */}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Col sm={12} md={6}>
                        <Form.Label>{utils.intlLang("Gender")}</Form.Label>
                        <Form.Control
                          className="dropdownform"
                          as="select"
                          name="gender"
                          value={values.gender}
                          onChange={handleChange}
                          isValid={touched.gender && !errors.gender}
                        >
                          <option value=""></option>
                          <option value="male">{utils.intlLang("Male")}</option>
                          <option value="female">
                            {utils.intlLang("Female")}
                          </option>
                          <option value="other">
                            {utils.intlLang("Other")}
                          </option>
                        </Form.Control>
                      </Col>

                      <ErrorMessage name="gender">
                        {(msg) => <div className="err_below">{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Group>
                        <Form.Label>{utils.intlLang("Ethnicity")}</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.ethnicity}
                          name="ethnicity"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="ethnicity">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      {/* <Form.Label>{utils.intlLang("Race")}</Form.Label> */}
                      <Form.Label>{utils.intlLang("Religion")}</Form.Label>
                      <Form.Control
                        className="dropdownform"
                        as="select"
                        name="race"
                        value={values.race}
                        onChange={handleChange}
                        isValid={touched.race && !errors.race}
                      >
                        <option value="">{utils.intlLang("Select")}</option>
                        <option value="hinduism">
                          {utils.intlLang("Hinduism")}
                        </option>
                        <option value="islam">{utils.intlLang("Islam")}</option>
                        <option value="buddhism">
                          {utils.intlLang("Buddhism")}
                        </option>
                        <option value="christianity">
                          {utils.intlLang("Christianity")}
                        </option>
                        <option value="other">{utils.intlLang("Other")}</option>
                      </Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Label>{utils.intlLang("Income")}</Form.Label>
                      <Form.Control
                        className="dropdownform"
                        as="select"
                        name="income"
                        value={values.income}
                        onChange={handleChange}
                        isValid={touched.income && !errors.income}
                      >
                        <option value="">{utils.intlLang("Select")}</option>
                        <option value="below_1lakh">
                          {utils.intlLang("below 1 lakh")}
                        </option>
                        <option value="2_lakh">
                          {utils.intlLang("2 lakh")}
                        </option>
                        <option value="3_lakh">
                          {utils.intlLang("3 lakh")}{" "}
                        </option>
                        <option value="4_lakh">
                          {utils.intlLang("4 lakh")}
                        </option>
                        <option value="above_5lakh">
                          {" "}
                          {utils.intlLang("above 5 lakh")}
                        </option>
                      </Form.Control>

                      <ErrorMessage name="income">
                        {(msg) => <div className="err_below">{msg}</div>}
                      </ErrorMessage>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label>
                        {utils.intlLang("Marital Status")}
                      </Form.Label>
                      <Form.Control
                        className="dropdownform"
                        as="select"
                        name="marital_status"
                        value={values.marital_status}
                        onChange={handleChange}
                        isValid={
                          touched.marital_status && !errors.marital_status
                        }
                      >
                        <option value="">{utils.intlLang("Select")}</option>
                        <option value="single">
                          {utils.intlLang("Single")}
                        </option>
                        <option value="married">
                          {utils.intlLang("Married")}
                        </option>
                        <option value="widowed">
                          {utils.intlLang("Widowed")}
                        </option>
                        <option value="separated">
                          {utils.intlLang("Separated")}
                        </option>
                        <option value="divorced">
                          {utils.intlLang("Divorced")}
                        </option>
                      </Form.Control>

                      <ErrorMessage name="marital_status">
                        {(msg) => <div className="err_below">{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>{utils.intlLang("State*")}</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.state}
                          name="state"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.state && !errors.state}
                        />
                        <ErrorMessage name="state">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>{utils.intlLang("Zip Code*")}</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.zipcode}
                          name="zipcode"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.zipcode && !errors.zipcode}
                        />
                        <ErrorMessage name="zipcode">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>{utils.intlLang("Country*")}</Form.Label>
                        <Form.Control
                          className="dropdownform"
                          as="select"
                          name="country"
                          value={values.country}
                          onChange={handleChange}
                        >
                          <option></option>
                          {countryOption}
                        </Form.Control>
                        <ErrorMessage name="country">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>{utils.intlLang("Town")}</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.town}
                          name="town"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.Town && !errors.Town}
                        />
                        <ErrorMessage name="town">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={9} md={2}>
                      <Form.Group >
                      <Form.Label >{utils.intlLang("Country Code*")}</Form.Label>
                        <Form.Control
                          as="select"
                          id="countryCode"
                          name="countryDialCode"
                          value={values.countryDialCode}
                          onChange={handleChange}
                          // disabled={is_deleted}
                          style={{
                            height: "50px",
                            border: "1px solid #e7e7e7",
                            borderRadius: "0px",
                          }}
                        >
                          <option>Select Country Code</option>
                          {country_dial_code}
                        </Form.Control>
                        <ErrorMessage name="countryDialCode">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={9} md={4}>
                      <Form.Group >
                        <Form.Label>{utils.intlLang("Phone*")}</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.phone}
                          name="phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.phone && !errors.phone}
                        />
                        <ErrorMessage name="phone">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>{utils.intlLang("Email*")}</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.email}
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.email && !errors.email}
                        />
                        <ErrorMessage name="email">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Group>
                        <Form.Label>{utils.intlLang("Education")}</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.education}
                          name="education"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="education">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label>{utils.intlLang("Industry")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="industry"
                        value={values.industry}
                        onChange={handleChange}
                        isValid={touched.industry && !errors.industry}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Group>
                        <Form.Label>{utils.intlLang("Employment")}</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.employment}
                          name="employment"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="employment">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={4}></Col>
                    <div className="col-md-12">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="password-change"
                      >
                        {utils.intlLang("Update")}
                      </button>
                    </div>
                  </Row>
                </Form>
              </Container>
            </div>
          )}
        />
        <ToastContainer />
      </div>
    );
  }
}
