import React from "react";

export default class Timer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <>
          {" "}
          {this.props.time}:{this.props.seconds}
        </>
      </div>
    );
  }
}
