import React from "react";
import BbbService from "../../services/bbbService";
import moment from "moment";
import utils from '../../utils/util';
export default class LiveRecordingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordingList: {},
    };
    this.bbbServ = new BbbService();
  }
  componentDidMount() {
    this.fetchRecording();
  }
  fetchRecording() {
    this.bbbServ
      .getRecordingList({ meetingId: this.props.courseId, meta_category: this.props.crsName })
      .then((res) => {
        this.setState({ recordingList: res?.data?.data?.response?.recordings });
      })
      .catch((err) => {});
  }

  render() {
    const { recordingList } = this.state;

    let detail = null;
    if (this.state.recordingList.recording) {
      detail = (
        <div>
          <div style={{ marginBottom: "50px" }}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="sidebar">
                    <aside>
                      <div class="tab-info">
                        <div class="tab-content tab-content-info">
                          {/* <!-- Single Tab --> */}
                          <div id="tab3" class="tab-pane fade active in">
                            <div class="info title">
                              {/* <!-- Start Course List --> */}
                              <div class="course-list-items acd-items acd-arrow">
                                <div class="panel-group symb" id="accordionnn">
                                  <div class="panel panel-default">
                                    <div class="panel-heading">
                                      <h4 class="panel-title">
                                        <a
                                          style={{ border: "1px solid #ccc", paddingLeft: "10px" }}
                                          data-toggle="collapse"
                                          data-parent="#accordionnn"
                                          href={`#${3}`}
                                          aria-expanded="false"
                                          class="collapsed"
                                        >
                                          {utils.intlLang("Recording List")}
                                        </a>
                                      </h4>
                                    </div>
                                    <div
                                      id={"3"}
                                      class="panel-collapse collapse"
                                      aria-expanded="false"
                                      style={{ height: " 0px" }}
                                    >
                                      <div class="panel-body">
                                        <ul>
                                          {Array.isArray(recordingList.recording) ? (
                                            recordingList.recording.map((el2, i2) => {
                                              return (
                                                <li key={el2.recordID}>
                                                  <div class="item name">
                                                    <i class="fas fa-play"></i>
                                                  </div>
                                                  <div class="item title">
                                                    <h5>{utils.intlLang("Session taken on:")} {moment(el2.startTime).format("lll")}</h5>
                                                  </div>
                                                  <div class="item info">
                                                    <a
                                                      href={el2.playback?.format?.url}
                                                      target="_blank"
                                                      style={{ cursor: "pointer" }}
                                                    >
                                                      {utils.intlLang("Watch")}
                                                    </a>

                                                    <span>{el2.view_duration}</span>
                                                  </div>
                                                </li>
                                              );
                                            })
                                          ) : (
                                            <li>
                                              <div class="item name">
                                                <i class="fas fa-play"></i>
                                              </div>
                                              <div class="item title">
                                                <h5>
                                                  {
                                                    <h5>
                                                      {utils.intlLang("Session taken on:")}{" "}
                                                      {moment(recordingList.recording.startTime).format("lll")}
                                                    </h5>
                                                  }
                                                </h5>
                                              </div>
                                              <div class="item info">
                                                <a
                                                  href={recordingList.recording?.playback?.format?.url}
                                                  target="_blank"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {utils.intlLang("Watch")}
                                                </a>

                                                <span>{recordingList.recording?.view_duration}</span>
                                              </div>
                                            </li>
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <!-- End Course List --> */}
                            </div>
                          </div>

                          {/* <!-- End Single Tab --> */}
                        </div>
                        {/* <!-- End Tab Content --> */}
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <>{detail}</>;
  }
}
