import React from "react";
import utils from '../../utils/util';

export const Error404 = () => {
  return (
    <div id="mainn">
      <div class="fof">
        <h1>{utils.intlLang("Error 404")}</h1>
        <p>
          {utils.intlLang("Unfortunately site content is not avilable in your country. Please wait till the time services get started in your region.")}
        </p>
      </div>
    </div>
  );
};
