import util from '../utils/util';

export default  class BlogService{

    getNotification(id) {
        return util.sendApiRequest("/notification/"+id,"GET",true).then(
            (response)=>{
                return response;
            },
            (error)=>{
                throw new Error(error);
            },
        );
    }
    listNotification(start,length,id,searchTxt,searchField) {
        let url = "/notification/list/"+start+"/"+length+"/"+id;
        
         return util.sendApiRequest(url, "GET",true).then(
             (response)=>{
                 return response;
             },
             (error)=>{
                 throw new Error(error);
             },
         );
     }
     updateNotification(id){
        return util.sendApiRequest("/notification", "PUT", true, id).then(
            (response)=>{
                return response;  
            },
            (error)=>{
                throw new Error(error);
            },
        );
     }
}