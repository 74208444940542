import React from "react";
import Iframe from "react-iframe";
import CourseService from "../../services/courseService";
import Container from "react-bootstrap/Container";
import InternalCourse from "./InternalOnlineCourse";
import LiveSessionDisplayCourse from "./LiveSessionDisplayCourse";
import Setting from "../../services/settingService";
import { Link } from "react-router-dom";
import TutorQuestion from "./TutorEvalquestion.json";
import moment from "moment";
import { Row, Col, ListGroup } from "react-bootstrap";
import { CheckBoxweekDays } from "./CheckBoxweekDays";
import JoinMeetingModal from "./JoinMettingModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LiveRecordingList from "./LiveRecordingList";
import Summary from "./Summary";
import Assignment from "./Assignment";
import LiveSessionCourse from "./LiveSessionCourse";
import BackButton from "../../components/BackButton";
import utils from '../../utils/util';
import CourseBackButton from "../../components/CourseBackButton";
import ReactParser from "react-html-parser";
export default class UserCourse extends React.Component {
  constructor(props) {
    super(props);
    this.course_id = props.match.params.id;
    this.state = { course: null, onlineSecret: null, onlineJoinUrl: null,showEventDetails:false, showSummary:true, showAssignment:false,showFiles:false };
    this.courseServ = new CourseService();
    this.settingServ = new Setting();
    this.weekDays = [
      { value: "sunday", name: "Sun" },
      { value: "monday", name: "Mon" },
      { value: "tuesday", name: "Tue" },
      { value: "wednesday", name: "Wed" },
      { value: "thrusday", name: "Thu" },
      { value: "friday", name: "Fri" },
      { value: "saturday", name: "Sat" },
    ];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.course_id) {
      this.courseServ.getCourse(this.course_id, this.props.match.params.user).then(
        (response) => {
          this.setState({
            course: response.data,
          });
        },
        (error) => {
          alert(utils.intlLang("Oops, something went wrong not able to fetch course."));
        }
      );
    }
    this.getSettingList();
  }

  getSettingList() {
    this.settingServ.listSetting().then(
      (response) => {
        response.data.forEach((element) => {
          if (element.name === "online_session_URL") {
            this.setState({ onlineJoinUrl: element.value });
          }
        });
      },
      (error) => {
        alert(utils.intlLang("Oops, something went wrong not able to fetch Setting list."));
      }
    );
  }

  render() {
    const { course } = this.state;

    const liveCourseData = () => {
      let data;
      switch (course.time) {
        case "daily":
          data = (
            <>
              <h4 style={{ marginTop: "10px" }}>{utils.intlLang("Live session start daily at :")} </h4>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{utils.intlLang("Start Date")}</th>
                    <th scope="col">{utils.intlLang("Start Time")}</th>
                    <th scope="col">{utils.intlLang("End Date")}</th>
                    <th scope="col">{utils.intlLang("End Time")}</th>
                  </tr>
                </thead>
                <tbody>
                  <td scope="col">1</td>
                  <td scope="col">{moment(course.start_date).format("ll")}</td>
                  <td scope="col"> {moment(course.start_time).format("hh:mm a")}</td>
                  <td scope="col">{moment(course.end_date).format("ll")}</td>
                  <td scope="col">{moment(course.end_time).format("hh:mm a")}</td>
                </tbody>
              </table>
            </>
          );
          break;
        case "weekly":
          data = (
            <>
              <h4 style={{ marginTop: "10px" }}>
                <div className="row">
                  <div style={{ padding: "18px" }} className="col-md-4">
                    {utils.intlLang("Live session start weekely on :")}{" "}
                  </div>
                  {this.weekDays.map((el, i) => (
                    <CheckBoxweekDays weekVal={course.weekDays} key={el.name} name="weekDays" value={el.name} />
                  ))}
                </div>
              </h4>

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{utils.intlLang("Start Date")}</th>
                    <th scope="col">{utils.intlLang("Start Time")}</th>
                    <th scope="col">{utils.intlLang("End Date")}</th>
                    <th scope="col">{utils.intlLang("End Time")}</th>
                  </tr>
                </thead>
                <tbody>
                  <td scope="col">1</td>
                  <td scope="col">{moment(course.start_date).format("ll")}</td>
                  <td scope="col"> {moment(course.start_time).format("hh:mm a")}</td>
                  <td scope="col">{moment(course.end_date).format("ll")}</td>
                  <td scope="col">{moment(course.end_time).format("hh:mm a")}</td>
                </tbody>
              </table>
            </>
          );
          break;
        case "custom":
          data = (
            <>
              <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>{utils.intlLang("Live session start on these following dates:")}</h4>

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">d{utils.intlLang("Start Date")}</th>
                    <th scope="col">d{utils.intlLang("Start Time")}</th>
                    <th scope="col">d{utils.intlLang("End Date")}</th>
                    <th scope="col">d{utils.intlLang("End Time")}</th>
                  </tr>
                </thead>
                <tbody>
                  <td scope="col">1</td>
                  <td scope="col">{moment(course.start_date).format("ll")}</td>
                  <td scope="col"> {moment(course.start_time).format("hh:mm a")}</td>
                  <td scope="col">{moment(course.end_date).format("ll")}</td>
                  <td scope="col">{moment(course.end_time).format("hh:mm a")}</td>
                </tbody>
              </table>
              <ListGroup style={{ fontSize: "14px", color: "#002147" }}>
                <Row>
                  {course.selectedDays.map((el, i) => {
                    return (
                      <Col md={3} key={i}>
                        <ListGroup.Item>{moment(el).format("ll")}</ListGroup.Item>
                      </Col>
                    );
                  })}
                </Row>
              </ListGroup>
            </>
          );
          break;

        default:
          data = <div>{utils.intlLang("loading")}</div>;
      }
      return data;
    };
    let coursedata;
    if (this.state.course) {
      if (this.state.course.end_date===null && this.state.course.section.length===0) {
        coursedata = (
          <Container style={{ minHeight: "600px", marginTop: "10px" }}>
             <div className="row crs-name">
            <div className="col-sm-6 col-xs-6 name">
              <CourseBackButton textColor="white" />
              <div className="coursetitle">{course.name}</div>
            </div>
            <div className="col-sm-6 col-xs-6">
              <div className="feedback-internal">
                <Link
                  className="crs-btn"
                  style={{ marginLeft: "3px" }}
                  to={`/testimonial/${this.course_id}`}
                >
                  {utils.intlLang("Add testimonial")}
                </Link>
              </div>
            
            </div>
          </div>
                <div className="blog-area full-blog standard full-blog">
              <div className="container">
                <div className="row">
                  <div className="blog-items">
                    <div className="blog-content col-md-10 col-md-offset-1">
                      <div className="single-item">
                        <div className="students-profiel adviros-details-area ">
                          <div className="col-md-15 info main-content">
                            <div className="tab-info">
                            <ul class="nav nav-pills">
                                <li
                                  className={this.state.showSummary ? "active" :""}
                                  >
                                  <Link to= {{ pathname: `/user/course/${this.course_id}/${this.props.match.params.user}`}}
                                        onClick={(e) => this.setState({showEventDetails:false, showSummary:true, showAssignment:false, showFiles:false})}
                                  >
                                      {utils.intlLang("Summary")}
                                  </Link>

                                  </li>
                               
                                  <li
                                  className={this.state.showAssignment ? "active" :""}
                                  >
                                    <Link to= {{ pathname: `/user/course/${this.course_id}/${this.props.match.params.user}`}}
                                        onClick={(e) => this.setState({showEventDetails:false, showSummary:false, showAssignment:true, showFiles:false})}
                                  >
                                      {utils.intlLang("Assignment")}
                                  </Link>
                                 </li>
                                 <li
                                  className={this.state.showFiles ? "active" :""}
                                  >
                                    <Link to= {{ pathname: `/user/course/${this.course_id}/${this.props.match.params.user}`}}
                                        onClick={(e) => this.setState({showEventDetails:false, showSummary:false, showAssignment:false, showFiles:true})}
                                  >
                                      {utils.intlLang("Course Files")}
                                  </Link>
                                 </li>
                                    </ul>

                                    {this.state.showSummary && (
                                      <div style={{wordBreak:"break-all"}}>
                                        {ReactParser(course.description)}
                                        </div>
                                       )}

                                        {this.state.showAssignment && (
                                          <Assignment course={course} assignment={course.assignment} course_id={course._id}/>)}

                                    {this.state.showFiles?
                                    (
                                      <>
                                      {this.state.course?(
                                        <>
                                        <table class="table">
                                        <thead>
                                          <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">{utils.intlLang("Named")}</th>
                                            <th scope="col">{utils.intlLang("Download")}</th>
                                          </tr>
                                        </thead>
                                           <tbody>
                                           <>
                                            {this.state.course.offline_course_content.length>0 &&  this.state.course.offline_course_content.map((el, i) => {
                                              return (
                                              <tr>
                                                <th scope="row">{i + 1}</th>
                                                <td>{el.split("$").slice(1).join()}</td>
                                                <td>
                                                  <a target="_blank" href={el}>
                                                    {utils.intlLang("Download")}
                                                  </a>
                                                </td>
                                              </tr>
                                            );
                                            })}
                                             {this.state.course.live_sesson_file_content.length>0 && this.state.course.live_sesson_file_content.map((el, i) => {
                                              return (
                                              <tr>
                                                <th scope="row">{i + 1}</th>
                                                <td>{el.split("$").slice(1).join()}</td>
                                                <td>
                                                  <a target="_blank" href={el}>
                                                    {utils.intlLang("Download")}
                                                  </a>
                                                </td>
                                              </tr>
                                            );
                                            })}
                                         </>
                                          </tbody>
                                          </table>
                                          </>
                                      ):null}
                                      </>
                                    ):null}
                     
                              {/* {
                              this.props.location.pathname==='/blogs' ? 
                               ( <News />):
                               (this.getComponent(this.props.location.pathname))} */}
                            </div>
                          </div>
                          </div>
                      </div>
                    </div>
                    </div>
                    </div>
                        </div>
                        </div>

            <div>
              
            </div>
          </Container>
        );
      } else if (this.state.course.course_type === "online") {
        coursedata = (
          <>
            <Iframe
              url={
                this.state.course.view_course_content +
                `?endpoint=${window.apiurl}/xapi&auth=Basic ${window.user.token}&actor={"name":["${window.user.data._id}"],"id":["${window.user.data._id}"],"mbox":["mailto:${window.user.data.email}"]}&activity_id=http://${this.course_id}`
              }
              width="100%"
              height="550px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"
              allowFullScreeen={true}
              target="_blank"
              controls
            />
            <div className="feedback-tincan">
              <Link
                to={{
                  pathname: `/feedback/${this.course_id}`,
                  state: {
                    questions: TutorQuestion,
                    title: "Tutor Evaluation Form",
                    type: "tutor_evaluation",
                    teacherId: this.state.course.created_by._id,
                  },
                }}
              >
                {utils.intlLang("Feedback")}
              </Link>
            </div>
            
          </>
        );
      } else if (this.state.course.start_date!==null && this.state.course.end_date!==null) {
        coursedata = (
          <>
            <Container style={{ minHeight: "600px", marginTop: "10px" }}>
              <div className="row crs-name">
                <div className="col-sm-6 col-xs-6 name">
                  <CourseBackButton textColor="white"/>
                  <div className="coursetitle">{course.name}</div>
                </div>
                <div className="col-sm-6 col-xs-6">
                <div className="feedback-internal">
                  <Link
                     className="crs-btn"
                    to={
                      `/testimonial/${this.course_id}`
                    }
                  >
                    {utils.intlLang("Add testimonial")}
                  </Link>
                </div>
              </div>
              </div>
              {course.live_sesson_file_content.length || course.offline_course_content.length ? (
                <>
                  {/* <h4 style={{ marginTop: "10px" }}>Course Files: </h4>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{utils.intlLang("Named")}</th>
                        <th scope="col">{utils.intlLang("Download")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        course.live_sesson_file_content.length?(
                          <>
                            {this.state.course.live_sesson_file_content.map((el, i) => {
                            return (
                          <tr>
                            <th scope="row">{i + 1}</th>
                            <td>{el.split("$").slice(1).join()}</td>
                            <td>
                              <a target="_blank" href={el}>
                                {utils.intlLang("Download")}
                              </a>
                            </td>
                          </tr>
                           );
                          })}
                          </>
                        ):(
                            <>
                              {this.state.course.offline_course_content.map((el, i) => {
                          return (
                          <tr>
                            <th scope="row">{i + 1}</th>
                            <td>{el.split("$").slice(1).join()}</td>
                            <td>
                              <a target="_blank" href={el}>
                                {utils.intlLang("Download")}
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                            </>
                        )
                      }
                    
                    </tbody>
                  </table>{" "} */}
                </>
              ) : null}
               <LiveSessionDisplayCourse {...this.props} />
               {
                 new Date(this.state.course.end_date) >= new Date() ? ( <div className="" style={{ marginBottom: "13px", marginTop: "13px", textAlign: "center" }}>
                 <JoinMeetingModal courseId={this.state.course._id} studentName={window.user?.data?.first_name} />
                              
               </div>) :(<div className="row" style={{textAlign:"center",marginTop:"40px"}}><button type="button" class="btn btn-secondary btn-sm" style={{background:"#808080",color:"#ffffff"}} disabled="disabled">Join Live Session </button></div>)
               }
             

              {/* <div className="container">
                <div className="row">
                  <div className="col-md-6">
                  <div className="tab-info">
                      <ul class="nav nav-pills">
                         <li 
                          className={this.props.location.pathname==='/user/course/60c710ea8bc4b209dc65c1e2/Pulkit' ? "active" :""}
                         >
                            <Link to= {{ pathname: "/user/course/60c710ea8bc4b209dc65c1e2/Pulkit"}}>
                              {utils.intlLang("News")}
                            </Link>
                          </li>
                       </ul>
                  </div>
                    <LiveSessionCourse
                      displayAccordian = {true}
                      startDate={course.start_date}
                      endDate={course.end_date}
                      startTime={course.start_time}
                      endTime={course.end_time}
                      type={course.time}
                      weekVal={course.weekDays}
                      selectedDays={course.selectedDays}
                    />
                  </div>
                  <div className="col-md-3">
                    <Summary summary={course.description} />
                  </div>
                  <div className="col-md-3">
                    <Assignment course={course} assignment={course.assignment} course_id={course._id}/>
                  </div>
                </div>
              </div> */}

            <div className="blog-area full-blog standard full-blog">
              <div className="container">
                <div className="row">
                  <div className="blog-items">
                    <div className="blog-content col-md-10 col-md-offset-1">
                      <div className="single-item">
                        <div className="students-profiel adviros-details-area ">
                          <div className="col-md-15 info main-content">
                            <div className="tab-info">
                            <ul class="nav nav-pills">
                                <li
                                  className={this.state.showSummary ? "active" :""}
                                  >
                                  <Link to= {{ pathname: `/user/course/${this.course_id}/${this.props.match.params.user}`}}
                                        onClick={(e) => this.setState({showEventDetails:false, showSummary:true, showAssignment:false, showFiles:false})}
                                  >
                                      {utils.intlLang("Summary")}
                                  </Link>

                                  </li>

                                  <li
                                  className={this.state.showEventDetails ? "active" :""}
                                   >
                                  <Link to= {{ pathname: `/user/course/${this.course_id}/${this.props.match.params.user}`}}
                                        onClick={(e) => this.setState({showEventDetails:true, showSummary:false, showAssignment:false, showFiles:false})}
                                  >
                                      {utils.intlLang("Events Details")}
                                      
                                  </Link>

                                </li>
                               
                                  <li
                                  className={this.state.showAssignment ? "active" :""}
                                  >
                                    <Link to= {{ pathname: `/user/course/${this.course_id}/${this.props.match.params.user}`}}
                                        onClick={(e) => this.setState({showEventDetails:false, showSummary:false, showAssignment:true, showFiles:false})}
                                  >
                                      {utils.intlLang("Assignment")}
                                  </Link>
                                 </li>
                                 <li
                                  className={this.state.showFiles ? "active" :""}
                                  >
                                    <Link to= {{ pathname: `/user/course/${this.course_id}/${this.props.match.params.user}`}}
                                        onClick={(e) => this.setState({showEventDetails:false, showSummary:false, showAssignment:false, showFiles:true})}
                                  >
                                      {utils.intlLang("Course Files")}
                                  </Link>
                                 </li>
                                    </ul>


                                    {this.state.showEventDetails && (<LiveSessionCourse
                                        displayAccordian = {true}
                                        startDate={course.start_date}
                                        endDate={course.end_date}
                                        startTime={course.start_time}
                                        endTime={course.end_time}
                                        type={course.time}
                                        weekVal={course.weekDays}
                                        selectedDays={course.selectedDays}
                                      />)}

                                    {this.state.showSummary && (
                                      <div style={{wordBreak:"break-all"}}>
                                        {ReactParser(course.description)}
                                        </div>
                                       )}

                                        {this.state.showAssignment && (
                                          <Assignment course={course} assignment={course.assignment} course_id={course._id}/>)}

                                    {this.state.showFiles?
                                    (
                                      <>
                                      {this.state.course?(
                                        <>
                                        <table class="table">
                                        <thead>
                                          <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">{utils.intlLang("Named")}</th>
                                            <th scope="col">{utils.intlLang("Download")}</th>
                                          </tr>
                                        </thead>
                                           <tbody>
                                           <>
                                            {this.state.course.offline_course_content.length>0 &&  this.state.course.offline_course_content.map((el, i) => {
                                              return (
                                              <tr>
                                                <th scope="row">{i + 1}</th>
                                                <td>{el.split("$").slice(1).join()}</td>
                                                <td>
                                                  <a target="_blank" href={el}>
                                                    {utils.intlLang("Download")}
                                                  </a>
                                                </td>
                                              </tr>
                                            );
                                            })}
                                             {this.state.course.live_sesson_file_content.length>0 && this.state.course.live_sesson_file_content.map((el, i) => {
                                              return (
                                              <tr>
                                                <th scope="row">{i + 1}</th>
                                                <td>{el.split("$").slice(1).join()}</td>
                                                <td>
                                                  <a target="_blank" href={el}>
                                                    {utils.intlLang("Download")}
                                                  </a>
                                                </td>
                                              </tr>
                                            );
                                            })}
                                         </>
                                          </tbody>
                                          </table>
                                          </>
                                      ):null}
                                      </>
                                    ):null}
                     
                              {/* {
                              this.props.location.pathname==='/blogs' ? 
                               ( <News />):
                               (this.getComponent(this.props.location.pathname))} */}
                            </div>
                          </div>
                          </div>
                      </div>
                    </div>
                    </div>
                    </div>
                        </div>
                        </div>

              <LiveRecordingList courseId={this.state.course._id} crsName={this.state.course.name} />
             
            </Container>
          </>
        );
      } else if (this.state.course.end_date===null && this.state.course.section.length >0) {
        return (<>
        <InternalCourse {...this.props} />
        <div className="blog-area full-blog standard full-blog">
              <div className="container">
                <div className="row">
                  <div className="blog-items">
                    <div className="blog-content col-md-10 col-md-offset-1">
                      <div className="single-item">
                        <div className="students-profiel adviros-details-area ">
                          <div className="col-md-15 info main-content">
                            <div className="tab-info">
                            <ul class="nav nav-pills">
                                <li
                                  className={this.state.showSummary ? "active" :""}
                                  >
                                  <Link to= {{ pathname: `/user/course/${this.course_id}/${this.props.match.params.user}`}}
                                        onClick={(e) => this.setState({showEventDetails:false, showSummary:true, showAssignment:false, showFiles:false})}
                                  >
                                      {utils.intlLang("Summary")}
                                  </Link>

                                  </li>
                               
                                  <li
                                  className={this.state.showAssignment ? "active" :""}
                                  >
                                    <Link to= {{ pathname: `/user/course/${this.course_id}/${this.props.match.params.user}`}}
                                        onClick={(e) => this.setState({showEventDetails:false, showSummary:false, showAssignment:true, showFiles:false})}
                                  >
                                      {utils.intlLang("Assignment")}
                                  </Link>
                                 </li>
                                 <li
                                  className={this.state.showFiles ? "active" :""}
                                  >
                                    <Link to= {{ pathname: `/user/course/${this.course_id}/${this.props.match.params.user}`}}
                                        onClick={(e) => this.setState({showEventDetails:false, showSummary:false, showAssignment:false, showFiles:true})}
                                  >
                                      {utils.intlLang("Course Files")}
                                  </Link>
                                 </li>
                                    </ul>

                                    {this.state.showSummary && (
                                      <div style={{wordBreak:"break-all"}}>
                                        {ReactParser(course.description)}
                                        </div>
                                       )}

                                        {this.state.showAssignment && (
                                          <Assignment course={course} assignment={course.assignment} course_id={course._id}/>)}

                                    {this.state.showFiles?
                                    (
                                      <>
                                      {this.state.course?(
                                        <>
                                        <table class="table">
                                        <thead>
                                          <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">{utils.intlLang("Named")}</th>
                                            <th scope="col">{utils.intlLang("Download")}</th>
                                          </tr>
                                        </thead>
                                           <tbody>
                                           <>
                                            {this.state.course.offline_course_content.length>0 &&  this.state.course.offline_course_content.map((el, i) => {
                                              return (
                                              <tr>
                                                <th scope="row">{i + 1}</th>
                                                <td>{el.split("$").slice(1).join()}</td>
                                                <td>
                                                  <a target="_blank" href={el}>
                                                    {utils.intlLang("Download")}
                                                  </a>
                                                </td>
                                              </tr>
                                            );
                                            })}
                                             {this.state.course.live_sesson_file_content.length>0 && this.state.course.live_sesson_file_content.map((el, i) => {
                                              return (
                                              <tr>
                                                <th scope="row">{i + 1}</th>
                                                <td>{el.split("$").slice(1).join()}</td>
                                                <td>
                                                  <a target="_blank" href={el}>
                                                    {utils.intlLang("Download")}
                                                  </a>
                                                </td>
                                              </tr>
                                            );
                                            })}
                                         </>
                                          </tbody>
                                          </table>
                                          </>
                                      ):null}
                                      </>
                                    ):null}
                     
                              {/* {
                              this.props.location.pathname==='/blogs' ? 
                               ( <News />):
                               (this.getComponent(this.props.location.pathname))} */}
                            </div>
                          </div>
                          </div>
                      </div>
                    </div>
                    </div>
                    </div>
                        </div>
                        </div>
        </>
          );
      } else {
        coursedata = (
          <div className="course-body">
            <strong>{utils.intlLang("Your Course Will Be Avaliable Soon.")}</strong>
          </div>
        );
      }
    }
    return (
      (
        <>
          {coursedata} <ToastContainer />
        </>
      ) || <></>
    );
  }
}
