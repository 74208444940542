import React, { useState, useEffect } from "react";
import blogService from "../../services/blogService";
import { Alert, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import utils from '../../utils/util';
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik, ErrorMessage } from "formik";
import Container from "react-bootstrap/Container";
import CountryCode from '../../components/countryCode.json';

function EventMetting(props) {
  const blogServ = new blogService();
  const { meetingId, name } = props.match.params;
  const [show, setshow] = useState(false);
  const [seatavailability, setAvailability] = useState(false);
  const [formDisplay, setFormDisplay] = useState(true);
  const [joinBtnDisplay, setJoinBtn] = useState(false);
  const [country_code, setCode] = useState([]);

  const [state, setState] = useState({
    eventMetting: {
        event_id: "",
        first_name: "",
        last_name: "",
        email: "",
        phoneNumber: "",
        countryDialCode: "",
        organisation: "",
        agree: false
    }
});
const phoneNumberRegex = RegExp(
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

)
let schema = Yup.object({
  first_name: Yup.string().required('First name is a required field'),
  last_name: Yup.string().required('Last name is a required field'),
  email: Yup.string().required(utils.intlLang('Email is a required field')).email(utils.intlLang("Invalid email")),
  organisation: Yup.string().required('Organisation is a required field'),
  agree: Yup.boolean()
  .oneOf([true], "You must accept the privacy and policy."),
  phoneNumber: Yup.string()
        .matches(phoneNumberRegex, utils.intlLang('Invalid phone number'))
        .required(utils.intlLang('Phone number is a required field')),
  countryDialCode: Yup.string().required(utils.intlLang('Required'))
});

  let timeId;
  useEffect(() => {
   
    checkAvailableSeats();
    let country_dial_code = [];
    for (let i = 0; i < CountryCode.length; i++) {
      country_dial_code.push(
        <option key={CountryCode[i].code + " " + i} value={CountryCode[i].dial_code }>
          {CountryCode[i].dial_code } {" "} {CountryCode[i].name}
        </option>
      );
    }
    setCode(country_dial_code)
  }, []);

  const checkAvailableSeats = async () => {
    try {
      setAvailability(false);
      let result = await blogServ.checkAvailableSeats({ meetingId, name });
     console.log(result);
     if(result.data.status==='Not Available')
     {
       setAvailability(true)
     }
     else
     {
      if(result.data.status==='Already Join')
      {
        setFormDisplay(false)
        setJoinBtn(true)
      }
      handleClickevent();
      timeId = setInterval(() => {
        handleClickevent();
      }, 30000);
      return () => {
        clearInterval(timeId);
      };
     }
    } catch (error) {
      //toast.error("Event not yet started");
      setAvailability(false);
    }
  };
  const handleClickevent = async () => {
    try {
      setshow(true);
      let result = await blogServ.joinMeeting({ meetingId, name });
      window.open(result.data.data);
      clearInterval(timeId);
      setshow(false);
    } catch (error) {
      toast.error(utils.intlLang("Event not yet started"));
      setshow(false);
    }
  };

  async function submitForm(values, action) {
    let phone = values.countryDialCode + "" + values.phoneNumber; 
    values.phoneNumber=phone
    values.event_id=meetingId
    values.username=name
    try {
      setshow(true);
      let result = await blogServ.submitMeetingForm(values);
      setFormDisplay(false);
      setJoinBtn(true);
      handleClickevent();
      timeId = setInterval(() => {
        handleClickevent();
      }, 30000);
      return () => {
        clearInterval(timeId);
      };
      setshow(false);
    } catch (error) {
      setshow(false);
    }
}

  return (
    <>
    <div 
    style={{  marginTop: "4rem",marginLeft: "35rem" }} 
    className={formDisplay===true ? "show row" :"hide row"}
    >
       <div className="heading" style={{ marginLeft: "2rem" }}>
                    <h3>{utils.intlLang("Event Meeting")}</h3>
                    <p>
                      {utils.intlLang(
                        "Please fill form to join meeting."
                      )}
                    </p>
                  </div>
                <Formik
                   validationSchema={schema}
                   initialValues={state.eventMetting}
                    enableReinitialize={true}
                    onSubmit={submitForm}
                    render={({
                      values,
                      errors,
                      status,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <div className="address addresslist">
                        <Container>
                          <Form onSubmit={handleSubmit}>
                          <Row>
                              <Col sm={9} md={6}>
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    placeholder={utils.intlLang("First Name*")}
                                    value={values.first_name}
                                    name="first_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.first_name && !errors.first_name}
                                  />
                                  <ErrorMessage name="first_name">
                                    {(msg) => (
                                      <div className="err_below">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                           
                            <Row>
                              <Col sm={9} md={6}>
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    placeholder={utils.intlLang("Last Name*")}
                                    value={values.last_name}
                                    name="last_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.last_name && !errors.last_name}
                                  />
                                  <ErrorMessage name="last_name">
                                    {(msg) => (
                                      <div className="err_below">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                           
                            <Row>
                              <Col sm={9} md={6}>
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    placeholder={utils.intlLang("Email*")}
                                    value={values.email}
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.email && !errors.email}
                                  />
                                  <ErrorMessage name="email">
                                    {(msg) => (
                                      <div className="err_below">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                           
                            <Row>
                              <Col sm={9} md={1}>
                                <Form.Group style={{width:"88px"}}>
                                  <Form.Control
                                    as="select"
                                    id="countryCode"
                                    name="countryDialCode"
                                    value={values.countryDialCode}
                                    onChange={handleChange}
                                    // disabled={is_deleted}
                                    style={{height:"50px", border: "1px solid #e7e7e7", borderRadius:'0px'}}
                                  >
                                    <option>Select Country Code</option>
                                    {country_code}
                                  </Form.Control>
                                  <ErrorMessage name="countryDialCode">
                                    {(msg) => (
                                      <div className="err_below">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                              <Col sm={9} md={5}>
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    id="pNumber"
                                    placeholder={utils.intlLang(
                                      "Phone number*"
                                    )}
                                    value={values.phoneNumber}
                                    name="phoneNumber"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={
                                      touched.phoneNumber && !errors.phoneNumber
                                    }
                                  />
                                  <ErrorMessage name="phoneNumber">
                                    {(msg) => (
                                      <div className="err_below">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={9} md={6}>
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    placeholder={utils.intlLang("Organisation*")}
                                    value={values.organisation}
                                    name="organisation"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.organisation && !errors.organisation}
                                  />
                                  <ErrorMessage name="organisation">
                                    {(msg) => (
                                      <div className="err_below">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={9} md={6}>
                                <Form.Group>
                                  <input
                                    type="checkbox"
                                    value={values.agree}
                                    name="agree"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.agree && !errors.agree}
                                    style={{ float:"left" }}
                                  />
                                  <span style={{ 
                                    paddingTop:"15px",
                                    paddingLeft:"10px",
                                    position:"absolute"
                                   }}>I agree to <b>privacy policy.</b></span>
                                  <ErrorMessage name="agree">
                                    {(msg) => (
                                      <div className="err_below">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <div className="col-md-12">
                                <div className="row">
                                  <button
                                    type="submit"
                                    name="submit"
                                    id="submit"
                                    className="btn btn-primary"
                                    style={{ background: "none 0px 0px repeat scroll rgb(0, 33, 71)" }}
                                  >
                                    {utils.intlLang("Submit")}{" "}
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-12 alert-notification">
                                <div id="message" className="alert-msg"></div>
                              </div>
                            </Row>
                          </Form>
                        </Container>
                      </div>
                    )}
                  />
    </div>
    <div 
  style={{ textAlign: "center", marginTop: "5rem",minHeight: "150px"}}
  className={joinBtnDisplay === true ? "show" : "hide"}
  >
{
        seatavailability===true ? utils.intlLang("Apologies for inconvenience, all our seat for this webinar are occupied. Please try next time.") : (<Button size="sm" onClick={handleClickevent}>
        {utils.intlLang("Join event")} {show && <i className="fas fa-spinner fa-spin fa-1x"></i>}
      </Button>)
      }
</div>
<ToastContainer />
    </>
  );
}

export default EventMetting;
