import React from "react";
import userservice from "../../services/userService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Redirect } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import countryList from "../../components/countryList.json";
import utils from "../../utils/util";
import CountryCode from '../../components/countryCode.json';
export default class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.stud_id = props.match.params.id;
    this.state = { student: null, redirect: false, error: "", successmessage: "" };
    this.state.student = {
      title: "Mr",
      first_name: "",
      last_name: "",
      email: "",
      address_1: "",
      address_2: "",
      address_3: "",
      state: "",
      country: "",
      city: "",
      zipcode: "",
      phone: "",
      recieve_newsletter: false,
      receive_personalised: false,
      is_active: true,
      courses: [],
      roles: ["student"],
      password: "",
      dob: "",
      gender: "",
      race: "",
      ethnicity: "",
      marital_status: "",
      income: "",
      education: "",
      employment: "",
      industry: "",
      countryDialCode: ""
    };
    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)?$/;
    this.studserv = new userservice();
    this.schema = Yup.object({
      title: Yup.string(),
      first_name: Yup.string().required(utils.intlLang("first name is a required field")).max(100, utils.intlLang("Too Long")),
      last_name: Yup.string().required(utils.intlLang("last name is a required field")).max(100, utils.intlLang("Too Long")),
      email: Yup.string().required(utils.intlLang("email is a required field")).email(),
      address_1: Yup.string().required(utils.intlLang("address 1 is a required field")).min(5, utils.intlLang("Too short")).max(100, utils.intlLang("Too Long")),
      address_2: Yup.string().min(5, utils.intlLang("Too short")).max(100, utils.intlLang("Too Long")),
      address_3: Yup.string().min(5, utils.intlLang("Too short")).max(100, utils.intlLang("Too Long")),
      country: Yup.string().required(utils.intlLang("country is a required field")).max(100, utils.intlLang("Too Long")),
      state: Yup.string().required(utils.intlLang("state is a required field")),
      city: Yup.string().required(utils.intlLang("city is a required field")),
      passsword: Yup.string(),
      zipcode: Yup.string().required(utils.intlLang("zipcode is a required field")),
      phone: Yup.string().required(utils.intlLang("phone number is a required field")).matches(phoneRegExp, utils.intlLang("invalid phone number")),
      countryDialCode: Yup.string().required(utils.intlLang('Required')),
      recieve_newsletter: Yup.boolean(),
      receive_personalised: Yup.boolean(),
      is_active: Yup.boolean(),
      password: Yup.string().required(utils.intlLang("password is a required field")),
      confirmPassword: Yup.string().oneOf([Yup.ref(utils.intlLang("password")), null], utils.intlLang("password must match")),
      dob: Yup.date(),
      gender: Yup.string(),
      race: Yup.string(),
      ethnicity: Yup.string(),
      marital_status: Yup.string(),
      income: Yup.string(),
      education: Yup.string(),
      employment: Yup.string(),
      industry: Yup.string(),
    });
  }
  submitStudentForm(values, actions) {
    actions.setSubmitting(false);
    this.setState({
      student: values,
    });
    if (this.stud_id) {
      this.state.student.roles = [];
      if (values.teacher === true) {
        values.teacher = "teacher";
        this.state.student.roles.push("teacher");
      } else if (values.teacher === false) {
        delete values.teacher;
      }
      if (values.admin === true) {
        values.admin = "admin";
        this.state.student.roles.push("admin");
      } else if (values.admin === false) {
        delete values.admin;
      }
      if (values.manager === true) {
        values.manager = "manager";
        this.state.student.roles.push("manager");
      } else if (values.manager === false) {
        delete values.manager;
      }
      if (values.student === true) {
        values.student = "student";
        this.state.student.roles.push("student");
      } else if (values.student === false) {
        delete values.student;
      }
      delete values.student;
      delete values.manager;
      delete values.teacher;
      delete values.admin;
      this.studserv.editUser(this.state.student).then(
        (response) => {
          this.setState({ redirect: true });
        },
        (error) => {
          this.setState({ redirect: false });
        }
      );
    } else {
      if (values.teacher === true) {
        values.teacher = "teacher";
        this.state.student.roles.push("teacher");
      }
      if (values.admin === true) {
        values.admin = "admin";
        this.state.student.roles.push("admin");
      }
      if (values.manager === true) {
        values.manager = "manager";
        this.state.student.roles.push("manager");
      }
      if (values.student === true) {
        values.student = "student";
        this.state.student.roles.push("student");
      }

      delete values.student;
      delete values.manager;
      delete values.teacher;
      delete values.admin;
      this.studserv.addUser(this.state.student).then(
        (response) => {
          this.setState({
            redirect: true,
            successmessage: "Registration Successful, Login With Your Registered Account",
            error: "",
          });
        },
        (error) => {

          this.setState({ redirect: false, error: error });
        }
      );
    }
  }

  render() {
    if (this.state.redirect === true) {
      alert(this.state.successmessage);
      return <Redirect to="/login" />;
    }
    let countryOption = [];
    for (let i = 0; i < countryList.length; i++) {
      countryOption.push(
        <option key={countryList[i].Code + " " + i} value={countryList[i].Name}>
          {countryList[i].Name}
        </option>
      );
    }

    let country_dial_code = [];
    for (let i = 0; i < CountryCode.length; i++) {
      country_dial_code.push(
        <option key={CountryCode[i].code + " " + i} value={CountryCode[i].dial_code }>
          {CountryCode[i].dial_code } {" "} {CountryCode[i].name}
        </option>
      );
    }
    return (
      <Formik
        validationSchema={this.schema}
        initialValues={this.state.student}
        enableReinitialize={true}
        onSubmit={this.submitStudentForm.bind(this)}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <div>
            <div className="login-area default-padding">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 col-md-offset-1">
                    <form
                      action="#"
                      id="register-form"
                      className="white-popup-block"
                      onSubmit={handleSubmit}
                    >
                      <div className="col-md-18 login-custom">
                        <h4>{utils.intlLang("Register a new account")}</h4>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Label>{utils.intlLang("Title*")}</Form.Label>
                            <Form.Control
                              className="dropdownform"
                              as="select"
                              name="title"
                              value={values.title}
                              onChange={handleChange}
                              isValid={touched.title && !errors.title}
                            >
                              <option value="Dr">{utils.intlLang("Dr")}</option>
                              <option value="Mr">{utils.intlLang("Mr")}</option>
                              <option value="Mrs">
                                {utils.intlLang("Mrs")}
                              </option>
                              <option value="Miss">
                                {utils.intlLang("Miss")}
                              </option>
                              <option value="Ms">{utils.intlLang("Ms")}</option>
                            </Form.Control>
                          </Col>

                          <Col sm={10} md={6}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("Email")}*
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={values.email}
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.email && !errors.email}
                              />
                              <ErrorMessage name="email">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                              {"This Email Is Already Registered" ===
                              this.state.error ? (
                                <div className="errormsg">
                                  {"email is already registered"}
                                </div>
                              ) : (
                                " "
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("First Name*")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={values.first_name}
                                name="first_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={
                                  touched.first_name && !errors.first_name
                                }
                              />
                              <ErrorMessage name="first_name">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("Last Name*")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={values.last_name}
                                name="last_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.last_name && !errors.last_name}
                              />
                              <ErrorMessage name="last_name">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("Address Line 1*")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={values.address_1}
                                name="address_1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage name="address_1">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("Address Line 2")}{" "}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={values.address_2}
                                name="address_2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage name="address_2">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("Address Line 3")}{" "}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={values.address_3}
                                name="address_3"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.address_3 && !errors.address_3}
                              />
                              <ErrorMessage name="address_3">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>{utils.intlLang("City*")}</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.city}
                                name="city"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.city && !errors.city}
                              />
                              <ErrorMessage name="city">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("ZipCode*")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={values.zipcode}
                                name="zipcode"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.zipcode && !errors.zipcode}
                              />
                              <ErrorMessage name="zipcode">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("State*")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={values.state}
                                name="state"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.state && !errors.state}
                              />
                              <ErrorMessage name="state">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("Country*")}
                              </Form.Label>
                              <Form.Control
                                className="dropdownform"
                                as="select"
                                name="country"
                                value={values.country}
                                onChange={handleChange}
                              >
                                <option></option>
                                {countryOption}
                              </Form.Control>
                              <ErrorMessage name="country">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("Date Of Birth")}
                              </Form.Label>
                              <Form.Control
                                type="date"
                                value={values.dob}
                                name="dob"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.dob && !errors.dob}
                              />
                              <ErrorMessage name="dob">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Col sm={12} md={6}>
                              <Form.Label>
                                {utils.intlLang("Gender")}
                              </Form.Label>
                              <Form.Control
                                className="dropdownform"
                                as="select"
                                name="gender"
                                value={values.gender}
                                onChange={handleChange}
                                isValid={touched.gender && !errors.gender}
                              >
                                <option value=""></option>
                                <option value="male">
                                  {utils.intlLang("Male")}
                                </option>
                                <option value="female">
                                  {utils.intlLang("Female")}
                                </option>
                                <option value="other">
                                  {utils.intlLang("Other")}
                                </option>
                              </Form.Control>
                            </Col>

                            <ErrorMessage name="gender">
                              {(msg) => <div className="err_below">{msg}</div>}
                            </ErrorMessage>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Label>
                              {utils.intlLang("Religion")}
                            </Form.Label>
                            <Form.Control
                              className="dropdownform"
                              as="select"
                              name="race"
                              value={values.race}
                              onChange={handleChange}
                              isValid={touched.race && !errors.race}
                            >
                              <option value="">
                                {utils.intlLang("Select")}
                              </option>
                              <option value="hinduism">
                                {utils.intlLang("Hinduism")}
                              </option>
                              <option value="islam">
                                {utils.intlLang("Islam")}
                              </option>
                              <option value="buddhism">
                                {utils.intlLang("Buddhism")}
                              </option>
                              <option value="christianity">
                                {utils.intlLang("Christianity")}
                              </option>
                              <option value="other">
                                {utils.intlLang("Other")}
                              </option>
                            </Form.Control>
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: "13px" }}>
                          <Col sm={12} md={6}>
                            <Form.Label>{utils.intlLang("Income")}</Form.Label>
                            <Form.Control
                              className="dropdownform"
                              as="select"
                              name="income"
                              value={values.income}
                              onChange={handleChange}
                              isValid={touched.income && !errors.income}
                            >
                              <option value="">
                                {utils.intlLang("Select")}
                              </option>
                              <option value="below_1lakh">
                                {utils.intlLang("below 1 lakh")}
                              </option>
                              <option value="2_lakh">
                                {utils.intlLang("2 lakh")}
                              </option>
                              <option value="3_lakh">
                                {utils.intlLang("3 lakh")}{" "}
                              </option>
                              <option value="4_lakh">
                                {utils.intlLang("4 lakh")}
                              </option>
                              <option value="above_5lakh">
                                {" "}
                                {utils.intlLang("above 5 lakh")}
                              </option>
                            </Form.Control>

                            <ErrorMessage name="income">
                              {(msg) => <div className="err_below">{msg}</div>}
                            </ErrorMessage>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Label>
                              {utils.intlLang("Marital Status")}
                            </Form.Label>
                            <Form.Control
                              className="dropdownform"
                              as="select"
                              name="marital_status"
                              value={values.marital_status}
                              onChange={handleChange}
                              isValid={
                                touched.marital_status && !errors.marital_status
                              }
                            >
                              <option value="">
                                {utils.intlLang("Select")}
                              </option>
                              <option value="single">
                                {utils.intlLang("Single")}
                              </option>
                              <option value="married">
                                {utils.intlLang("Married")}
                              </option>
                              <option value="widowed">
                                {utils.intlLang("Widowed")}
                              </option>
                              <option value="separated">
                                {utils.intlLang("Separated")}
                              </option>
                              <option value="divorced">
                                {utils.intlLang("Divorced")}
                              </option>
                            </Form.Control>

                            <ErrorMessage name="marital_status">
                              {(msg) => <div className="err_below">{msg}</div>}
                            </ErrorMessage>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("Education")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={values.education}
                                name="education"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage name="education">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Label>
                              {utils.intlLang("Industry")}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="industry"
                              value={values.industry}
                              onChange={handleChange}
                              isValid={touched.industry && !errors.industry}
                            ></Form.Control>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("Employment")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={values.employment}
                                name="employment"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage name="employment">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("Ethnicity")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={values.ethnicity}
                                name="ethnicity"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage name="ethnicity">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={9} md={2}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("Country Code*")}
                              </Form.Label>
                              <Form.Control
                                as="select"
                                id="countryCode"
                                name="countryDialCode"
                                value={values.countryDialCode}
                                onChange={handleChange}
                                // disabled={is_deleted}
                                style={{
                                  height: "50px",
                                  border: "1px solid #e7e7e7",
                                  borderRadius: "0px",
                                }}
                              >
                                <option>Select Country Code</option>
                                {country_dial_code}
                              </Form.Control>
                              <ErrorMessage name="countryDialCode">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={4}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("Phone*")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                autoComplete="new-phone"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.phone && !errors.phone}
                              />
                              <ErrorMessage name="phone">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("Password*")}
                              </Form.Label>
                              <Form.Control
                                type="password"
                                name="password"
                                autoComplete="new-password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.password && !errors.password}
                              />
                              <ErrorMessage name="password">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>
                                {utils.intlLang("Confirm Password*")}
                              </Form.Label>
                              <Form.Control
                                type="password"
                                name="confirmPassword"
                                autoComplete="new-password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={
                                  touched.confirmPassword &&
                                  !errors.confirmPassword
                                }
                              />
                              <ErrorMessage name="confirmPassword">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                              {`confirm password is a required` ===
                              this.state.error ? (
                                <div className="errormsg">
                                  {"Confirm Password is a required"}
                                </div>
                              ) : (
                                " "
                              )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <div className="rcvnws">
                          <strong>
                            {utils.intlLang("Receive Personalised")}{" "}
                          </strong>
                        </div>
                        <Row>
                          <Col sm={12} md={12}>
                            <Form.Group
                              controlId="form20"
                              className="form20rcvper"
                            >
                              <Form.Check
                                type="checkbox"
                                value={values.receive_personalised}
                                name="receive_personalised"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={
                                  touched.receive_personalised &&
                                  !errors.receive_personalised
                                }
                              />
                              <ErrorMessage name="receive_personalised">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={12}>
                            <div className="rcvnws">
                              <strong>
                                {utils.intlLang("Receive NewsLetter")}
                              </strong>
                            </div>
                            <Form.Group
                              controlId="form20"
                              className="form20rcvnws"
                            >
                              <Form.Check
                                type="checkbox"
                                value={values.recieve_newsletter}
                                name="recieve_newsletter"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={
                                  touched.recieve_newsletter &&
                                  !errors.recieve_newsletter
                                }
                              />
                              <ErrorMessage name="recieve_newsletter">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <div className="col-md-12">
                          <div className="row">
                            <button type="submit">
                              {utils.intlLang("Sign Up")}
                            </button>
                          </div>
                        </div>
                        <p className="link-bottom">
                          {utils.intlLang("Are you a member?")}{" "}
                          <Link to={{ pathname: "/login" }}>
                            {utils.intlLang("Login now")}
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}
