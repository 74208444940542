import React from "react";
import ReactParser from "react-html-parser";
import utils from '../../utils/util';
import { Link } from "react-router-dom";
export default class Assignment extends React.Component {
  constructor(props){
    super(props);
 this.state= {
    count:1
    }
  }

  render() {
    const { assignment,course_id } = this.props;
let count=0;
let list=[]
assignment.map((el,index)=>{
  if(el.is_publish)
  {
    count=count+1
   list.push(
   <p style={{  wordBreak:"break-all"  }}>
   <div class="item name">
   {count}{'.'} {" "}
   <Link
       className="crs-btn"
       to={
           `/assignment/${course_id}/${el.assignment_id}`
       }
       >
           {el.title}
       </Link>
       
       </div>
   <div class="item title"></div>
 </p>)
  }
})

    console.log(course_id,'course_id')
    return (
      <>
        <div style={{ marginBottom: "0px" }}>
        {
          list
        }
       
        </div>
        
      </>
    );
  }
}
