import React , {useState}from 'react';
import { Link } from "react-router-dom";
import utils from '../utils/util';

class UserMenu extends React.Component {
    constructor(props) {
        super(props);
      
        this.state = {
          toggleCourse:false,
          toggleMessage:false,
          toggleAssignment:false,
          angleCourse:false,
          angleMsg:false,
          angleAssignment:false
        }
    }
   acctiveClass(currentUrl)
   {
    console.log('this.props.currentpage',this.props.currentpage)
   } 
   //Toggle course
   handleToggleCourse = () => {
    this.setState({toggleCourse:!this.state.toggleCourse,toggleMessage :false,angleCourse:!this.state.angleCourse,angleMsg:false,angleAssignment:false})
    console.log(this.state.toggleCourse,'show toggle');
   }
   //Toggle message
   handleToggleMessage = () => {
    this.setState({toggleMessage:!this.state.toggleMessage,toggleCourse :false,angleMsg:!this.state.angleMsg,angleCourse:false,angleAssignment:false})
    
   }
   //Toggle Assignment
   handleToggleAssignment = () => {
    this.setState({toggleAssignment:!this.state.toggleAssignment,toggleCourse :false,angleAssignment:!this.state.angleAssignment,angleCourse:false,angleMsg:false})
    
   }

    render () {
      console.log(this.state.toggleCourse,'Toggle')
       let classes = "fa fa-angle-";
       classes += this.state?.angleCourse ? "down" : "left"

       let classMsg = "fa fa-angle-";
       classMsg += this.state?.angleMsg ? "down" : "left"

       let classAssignment = "fa fa-angle-";
       classAssignment += this.state?.angleAssignment ? "down" : "left"
       
        return (
            <nav class="navigation">
            <ul class="mainmenu">
           
              <li className={this.props.currentpage==='/user/profile' ? "active" :""}   >
              <Link to= {{ pathname: "/user/profile"}}>
                      <i class="fa fa-home" ></i>{" "}{" "}{utils.intlLang("Dashboard")}
              </Link>
              </li>
              {/* <li className={this.props.currentpage==='/user/order' ? "active" :""} >
                <Link to= {{ pathname: "/user/order"}}>
                        <i class="fa fa-shopping-cart" ></i>{" "}{" "}{utils.intlLang("Orders")}
                </Link>
                 
              </li> */}
              <li className={this.props.currentpage==='/user-profile' ? "active" :""} >
                <Link to= {{ pathname: "/user-profile"}}>
                        <i class="fa fa-user" ></i>{" "}{" "}{utils.intlLang("Profile")}
                </Link>
              </li>
              <li>
              <Link  onClick={this.handleToggleCourse}>
                <i class="fa fa-book" ></i>{" "}{" "}{utils.intlLang("Course")} 
                <i class={classes} style={{float:"right"}}></i>
              </Link>
                {/* <a href="#"><i class="fa fa-book" ></i>{" "}{" "}{utils.intlLang("Course")} 
              <i class="fa fa-angle-left" style={{float:"right"}}></i>
              </a> */}
              {
                this.state.toggleCourse ? (  <ul class="submenu" style={{ "display":this.props.currentpage ==='/user-course' || this.props.currentpage ==='/user-expired-course' || this.props.currentpage ==='/user-live-session' ? "contents" : "" }} >
                <li className={this.props.currentpage==='/user-course' ? "active" :""} >
                      <Link to= {{ pathname: "/user-course"}}>
                              <i class="fa fa-book" ></i>{" "}{" "}{utils.intlLang("My Courses")}
                      </Link>
                   
                </li>
                 <li className={this.props.currentpage==='/user-expired-course' ? "active" :""} >
                     <Link to= {{ pathname: "/user-expired-course"}}>
                              <i class="fa fa-book" ></i>{" "}{" "}{utils.intlLang("Expired Courses")}
                      </Link>
                  </li>
                 <li className={this.props.currentpage==='/user-live-session' ? "active" :""} >
                    <Link to= {{ pathname: "/user-live-session"}}>
                              <i class="fa fa-book" ></i>{" "}{" "}{utils.intlLang("Live Session Details")}
                    </Link>
                  </li>
              </ul>) :null
              }
              </li>
              <li>
                {/* <a href=""><i class="fa fa-comment" ></i>{" "}{" "}{utils.intlLang("Message")}
              <i class="fa fa-angle-left" style={{float:"right"}}></i></a> */}

              <Link  onClick={this.handleToggleMessage}>
                <i class="fa fa-book" ></i>{" "}{" "}{utils.intlLang("Message")} 
                <i class={classMsg} style={{float:"right"}}></i>
              </Link>

                {
                  this.state?.toggleMessage?(<ul class="submenu" style={{ "display":this.props.currentpage ==='/user-message' || this.props.currentpage ==='/user-notification' ? "contents" : "" }}>
                  <li className={this.props.currentpage==='/user-message' ? "active" :""} >
                      <Link to= {{ pathname: "/user-message"}}>
                                <i class="fa fa-comment" ></i>{" "}{" "}{utils.intlLang("Message List")}
                      </Link>
                    </li>
                  <li className={this.props.currentpage==='/user-notification' ? "active" :""} >
                      <Link to= {{ pathname: "/user-notification"}}>
                                <i class="fa fa-bell" ></i>{" "}{" "}{utils.intlLang("Notification")}
                      </Link>
                    </li>
                </ul>
              ):null
                }
              </li>
              <li>
              

              <Link  onClick={this.handleToggleAssignment}>
                <i class="fa fa-file" ></i>{" "}{" "}{utils.intlLang("Assignment")} 
                <i class={classAssignment} style={{float:"right"}}></i>
              </Link>

                {
                  this.state?.toggleAssignment?(<ul class="submenu" style={{ "display":this.props.currentpage ==='/pending-assignment' || this.props.currentpage ==='/complete-assignment' ? "contents" : "" }}>
                  <li className={this.props.currentpage==='/pending-assignment' ? "active" :""} >
                      <Link to= {{ pathname: "/pending-assignment"}}>
                                <i class="fa fa-file" ></i>{" "}{" "}{utils.intlLang("Pending Assignment")}
                      </Link>
                    </li>
                  <li className={this.props.currentpage==='/complete-assignment' ? "active" :""} >
                      <Link to= {{ pathname: "/complete-assignment"}}>
                                <i class="fa fa-file" ></i>{" "}{" "}{utils.intlLang("Complete Assignment")}
                      </Link>
                    </li>
                </ul>
              ):null
                }
              </li>
            </ul>
          </nav>
        );
    }
}

export default UserMenu;
