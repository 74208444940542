import util from "../utils/util";
const axios = require("axios");
export default class CourseService {
  getCourse(id, name) {
    return util.sendApiRequest("/course/section/" + id + "/" + name, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  getAssignment(id) {
    return util.sendApiRequest("/assignment/" + id , "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  getAssignmentStatus(body) {
    return util.sendApiRequest("/assignment/getAssignmentStatus" , "POST", true,body).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  uploadAssignment(assignment) {
    const formData = new FormData();
    for (let prop in assignment) {
      formData.append(prop, assignment[prop]);
    }
    const token = window.user ? window.user.token : "no-token";
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };
    return axios.post(window.apiurl + "/assignment/uploadAssignment/", formData, config);
  }
  getPublicCourse(id) {
    return util.sendApiRequest("/course/frontend/course/" + id, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  listCourse(start, length, searchTxt, searchField) {
    let url = "/course/frontend/list/" + start + "/" + length;
    if (searchTxt && searchField) {
      url = "/course/frontend/list/" + start + "/" + length + "/" + searchTxt + "/" + searchField.toLowerCase();
    }
    return util.sendApiRequest(url, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  addCourse(course) {
    return util.sendApiRequest("/course", "POST", true, course).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  popularCourseFrontend() {
    let url = "/course/popularcourse";
    return util.sendApiRequest(url, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  likesCount(id) {
    return util.sendApiRequest("/course/" + id, "POST", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  totalCourseCount() {
    return util.sendApiRequest("/course/total/count/", "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  listCategoryBasedCourse(id) {
    return util.sendApiRequest("/course/courseCategory/" + id, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  //handle viewed course by student
  handleViewedCourse(body) {
    return util.sendApiRequest("/course/lesson/viewed", "POST", true, body).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  //list all viewed  lesson
  ListViewedCourse({ courseId, userId }) {
    return util.sendApiRequest("/course/lesson/viewed/" + courseId + "/" + userId, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
}
