import React from "react";
import Row from "react-bootstrap/Row";
import { Redirect } from "react-router-dom";
import Col from "react-bootstrap/Col";
import ReCAPTCHA from "react-google-recaptcha";
import Newssubscription from "../../services/newsSubscription";
import utils from "../../utils/util";
export default class newsAndSubscriptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      isVerified: false,
      recaptchaResponse: null,
      redirect: false,
      loading: false,
      error: "",
      successMessage: "",
    };
    this.refcaptcha = React.createRef();

    this.newsSubscriptionServ = new Newssubscription();
    this.handleCaptchaResponseChange = this.handleCaptchaResponseChange.bind(this);
  }

  handleName(event) {
    this.setState({
      name: event.target.value,
    });
  }
  handleEmail(event) {
    this.setState({
      email: event.target.value,
    });
  }
  handleCaptchaResponseChange(value) {
    this.setState({
      recaptchaResponse: value,
    });
  }

  newssubscription(e) {
    e.preventDefault();
    this.newsSubscriptionServ
      .savenewssubscription(this.state.name, this.state.email, this.state.recaptchaResponse)
      .then(
        (response) => {
          this.setState({
            successMessage: "Successfuly subscribed to newsletter ",
          });
          if (this.refcaptcha.current) {
            this.refcaptcha.current.captcha.style.display = "none";
          }
        },
        (error) => {
          this.setState({ redirect: false, error: error.toString() });
        }
      );
  }

  render() {
    return (
      <form action="#" id="login-form" onSubmit={this.newssubscription.bind(this)}>
        <div className="news">
          <Row>
            <Col sm={12} md={6}>
              <strong>{utils.intlLang("Sign up to the Publishing Clinic newsletter to receive the latest news and updates")}</strong>
            </Col>
            <Col sm={12} md={2}>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="inputName" className="sr-only">
                    {utils.intlLang("Name")}
                  </label>
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder={utils.intlLang("Enter Name")}
                    onChange={this.handleName.bind(this)}
                    required
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} md={2}>
              <div>
                <div className="form-group">
                  <label htmlFor="inputEmail" className="sr-only">
                    {utils.intlLang("Email")}
                  </label>
                  <input
                    type="email"
                    id="inputEmail"
                    className="form-control"
                    placeholder={utils.intlLang("Enter Email Address")}
                    onChange={this.handleEmail.bind(this)}
                    required
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} md={2}>
              <button type="submit" className="nws btn btn-primary btn-sm">
                {utils.intlLang("submit")}
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="captcha">
                <ReCAPTCHA
                  ref={this.refcaptcha}
                  sitekey={window.captcha_site_key}
                  onChange={this.handleCaptchaResponseChange}
                />

                <div className="errormsg">{this.state.error}</div>
              </div>
              {!this.state.successMessage == "" ? (
                <div className="alert alert-success">{this.state.successMessage}</div>
              ) : null}
            </Col>
          </Row>
        </div>
      </form>
    );
  }
}
