import React from "react";
import userservice from "../../services/userService";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import utils from "../../utils/util";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.token = props.match.params.token;
    this.state = { newPassword: "", verifyPassword: "", token: "", loading: false, redirect: false };
    this.userv = new userservice();
  }

  reset_password(e) {
    e.preventDefault();

    this.userv.reset_password(this.state.newPassword, this.state.verifyPassword, this.token).then(
      (response) => {
        alert(utils.intlLang("Your password is successfully updated, please log in with new password."));
        this.props.history.push('/login')
      },
      (error) => {
        alert(utils.intlLang("Your link is expired."));
      }
    );
  }

  handleNewPasswordChange(event) {
    this.setState({
      newPassword: event.target.value,
    });
  }
  handleVerifyPassword(event) {
    this.setState({
      verifyPassword: event.target.value,
    });
  }
  render() {
    return (
      <div>
        <div className="login-area default-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <form
                  action="#"
                  id="login-form"
                  className="white-popup-block"
                  onSubmit={this.reset_password.bind(this)}
                >
                  <div className="col-md-8 login-custom col-centered">
                    <h4>{utils.intlLang("Reset Password!")}</h4>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="form-group">
                          <label htmlFor="inputNewPassword" className="sr-only">
                            {utils.intlLang("New Password")}
                          </label>
                          <input
                            type="password"
                            id="inputNewPassword"
                            className="form-control"
                            placeholder={utils.intlLang("New Password")}
                            onChange={this.handleNewPasswordChange.bind(this)}
                            required
                            autoFocus
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group">
                          <label htmlFor="inputVerifyPassword" className="sr-only">
                            {utils.intlLang("Confirm Password")}
                          </label>
                          <input
                            type="password"
                            id="inputVerifyPassword"
                            className="form-control"
                            placeholder={utils.intlLang("Confirm Password")}
                            onChange={this.handleVerifyPassword.bind(this)}
                            required
                            autoFocus
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <button type="submit">{utils.intlLang("submit")}</button>
                        <Link title="Lost Password" to={{ pathname: "/login" }} className="lost-pass-link">
                          {utils.intlLang("Login")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
