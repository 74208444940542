import React from "react";
import Pagination from "react-bootstrap/Pagination";
import { Link } from "react-router-dom";
import UserService from "../../services/userService";
import lmsService from "../../services/lmsService";
import OrderService from "../../services/orderService";
import Banner from "../Banner/banner";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import LikesCount from "../../services/likeService";
import Notification from "../../services/notificationService";
import setting from "../../services/settingService";
import { FeedBackForm } from "./FeedBackForm";
import UserForm from "./UserForm";
import PasswordChanged from "./PasswordChanged";
import Message from "./Message";
import utils from '../../utils/util';
import Livesessiondetails from "./liveSessionList"
import UserMenu from "../../components/userMenu"
const axios = require("axios");
export default class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.user_id = window.user.data._id;
    this.orderuser_id = window.user.data._id;
    this.image = "";
    this.backref = null;
    this.userData = [];
    this.search = { start: 0, perPage: 10, searchTxt: "", searchField: "" };
    this.state = {
      totalCount: 0,
      showModal: false,
      courseResult: [{ result: {}, courseId: "", status: "" }],
      file: null,
      previewImage: null,
      redirect: false,
      loading: false,
      scrollPosition: 400,
      sucessMessage: "",
      errorMessage: "",
      user: "",
      profileImg: "",
      orderList: [],
      userCourse: [],
      likescount: [],
      notificationList: [],
      settingList: [],
      student: {
        title: "Mr",
        first_name: "",
        last_name: "",
        email: "",
        address_1: "",
        address_2: "",
        address_3: "",
        town: "",
        state: "",
        country: "",
        city: "",
        zipcode: "",
        phone: "",
        recieve_newsletter: false,
        receive_personalised: false,
        is_active: false,
        courses: [],
        roles: [],
        password: "",
        newPassword: "",
        verifyPassword: "",
        dob: "",
        gender: "",
        race: "",
        ethnicity: "",
        marital_status: "",
        income: "",
        education: "",
        employment: "",
        industry: "",
      },
    };
    this.userServ = new UserService();
    this.orderServ = new OrderService();
    this.likesServ = new LikesCount();
    this.lmsServ = new lmsService();
    this.notificationServ = new Notification();
    this.settingserv = new setting();

    if (this.user_id) {
      this.userServ.getUser(this.user_id, true).then(
        (response) => {
          if (response.data.dob) {
            response.data.dob = response.data.dob.split("").splice(0, 10).join("");
          }
          this.userData.push(response.data);
          let filterArry = response.lmsResult.map((el) => {
            let result = el.statement.result;
            let status = el.statement.verb.display["en-US"];
            let courseId = el.statement.object.id.split("//").slice(1).join("");
            return { result, status, courseId };
          });
          this.setState({
            userCourse: response.data,
            profileImg: response.data.image,
            courseResult: filterArry,
          });

          let tempUser = JSON.parse(JSON.stringify(response.data));
          this.setState({ student: tempUser });

          this.setState({ user: response.data });
        },
        (error) => {
          alert(utils.intlLang("Oops, something went wrong not able to fetch user details."));
        }
      );
    }

    if (this.orderuser_id) {
      this.orderServ.getUserOrder(this.orderuser_id).then(
        (response) => {
          this.setState({ orderList: response.data });
        },
        (error) => {
          alert(utils.intlLang("Oops, something went wrong not able to fetch user order details."));
        }
      );
    }
  }
  componentDidMount() {
    window.scrollTo(0, 100);
    this.countLikes();
    this.listAllLikes();
    this.getSettingVal();
    this.getNotificationList();
  }
  getSettingVal() {
    this.settingserv.listSetting(0, 10000000).then(
      (response) => {
        this.setState({ settingList: response.data });
      },
      (error) => {
        this.setState({ settingList: [] });
      }
    );
  }
  getNotificationList() {
    this.notificationServ.listNotification(this.search.start, this.search.perPage, this.user_id).then(
      (response) => {
        this.setState({ notificationList: response.data, totalCount: response.total });
      },
      (error) => {
        this.setState({ notificationList: [], totalcount: 0 });
      }
    );
  }
  handlePaging(e) {
    if (e.target.text) {
      this.search.start = parseInt(e.target.text) * this.search.perPage - this.search.perPage;
      this.getNotificationList();
    }
  }

  scrollUp(amountToScroll) {
    this.setState({
      scrollPosition: this.state.scrollPosition + amountToScroll,
    });
    window.scrollTo(0, this.state.scrollPosition);
  }

  countLikes(id) {
    this.likesServ.likesCount(id).then(
      (response) => {
        if (response.data === null) {
          response.data = [];
        } else {
          this.listAllLikes();
        }
      },
      (error) => {

      }
    );
  }
  //download course certificate
  async handleCouseCertificateDownload(crsId) {
    let certificateData = {};
    certificateData.crsId = crsId;
    certificateData.studId = this.user_id;
    let token = window.user ? window.user.token : "no-token";
    const url = window.apiurl + "/certificate";
    const data = certificateData;
    this.setState({ loading: true });
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (response) {
        this.setState({ loading: false });
      }
      const json = await response.blob();
      let blob = new Blob([json], { type: "application/pdf" });
      let downloadUrl = URL.createObjectURL(blob);
      //Build a URL from the file
      window.open(downloadUrl);
    } catch (error) { }
  }
  listAllLikes() {
    this.likesServ.listLike().then(
      (response) => {
        this.setState({
          likescount: response.data,
        });
      },
      (error) => {
        this.setState({
          likescount: [],
        });
      }
    );
  }

  async handleDownload(id, e) {
    let token = window.user ? window.user.token : "no-token";
    const url = window.apiurl + "/create-pdf";
    const data = { id, action: 2 };
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const json = await response.blob();
      let blob = new Blob([json], { type: "application/pdf" });
      let downloadUrl = URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = downloadUrl;
      a.download = "data.pdf";
      document.body.appendChild(a);
      a.click();
    } catch (error) { }
  }

  showMessage() {
    if (this.state.successMessage) {
      return <div className="alert alert-success">{this.state.successMessage}</div>;
    }
    if (this.state.errorMessage) {
      return <div className="alert alert-danger">{this.state.errorMessage}</div>;
    }
  }
  onClick() {
    let deactivateAccount = window.confirm(utils.intlLang("Are you sure to delete your account"));
    if (deactivateAccount === false) {
      window.location.reload(false);
    } else {
      if (this.userData) {
        for (let i = 0; i < this.userData.length; i++) {
          this.userData[i].is_deleted = true;
          this.setState({
            userCourse: this.userData,
          });
          if (this.user_id) {
            this.userServ.editUser(this.state.userCourse).then(
              (response) => {
                if (deactivateAccount === true) {
                  this.setState({ redirect: true });
                  this.userServ.logout();
                  window.location.reload(false);
                }
              },
              (error) => { }
            );
          }
        }
      }
    }
  }

  lmsResultCrsMapping(course, courseResult) {
    for (let cr of courseResult) {
      if (cr.courseId == course._id) {

        return (
          <div className="score">
            {cr.result.score.scaled * 100 === 100
              ? cr.result.score.scaled * 100 + " %"
              : (cr.result.score.scaled * 100).toFixed(2) + " %"}
            {}
          </div>
        );
      }
    }
  }
  courseSuccessResult(course, courseResult) {
    for (let cr of courseResult) {
      if (cr.courseId == course._id) {
        return <div className={cr.result.success ? "success" : "failed"}>{cr.status}</div>;
      }
    }
  }

  render() {
    if (this.props.location?.state?.prevPath == "/user/course") {
      this.backref && this.backref.click();
    }
    let user2ndaddr = "";
    let user3rdaddr = "";
    let currencyVal = "";
    for (let i = 0; i < this.state.settingList.length; i++) {
      if (this.state.settingList[i].name === "currencySymbol") {
        currencyVal = this.state.settingList[i].value;
      }
    }
    let active = Math.ceil((this.search.start + 1) / this.search.perPage);
    let pages = Math.ceil(this.state.totalCount / this.search.perPage);
    let items = [];
    for (let number = 1; number <= pages; number++) {
      items.push(
        <Pagination.Item key={number} onClick={this.handlePaging.bind(this)} active={number === active}>
          {number}
        </Pagination.Item>
      );
    }
    if (this.state.user) {
      user2ndaddr = this.state.user.address_2 === "undefined" ? "" : this.state.user.address_2;
      user3rdaddr = this.state.user.address_3 === "undefined" ? "" : this.state.user.address_3;
    }
    if (this.state.redirect) {
      window.location.reload(false);
      this.userServ.logout();
      return <Redirect to="/"></Redirect>;
    }
    let notificationData = [];
    let data = [];
    if (this.state.notificationList) {
      for (let i = 0; i < this.state.notificationList.length; i++) {
        notificationData.push(
          <div key={"" + i} className="news">
            <div className="snippet">{ReactHtmlParser(this.state.notificationList[i].message)}</div>
            <div>
              {this.state.notificationList[i].created_by.first_name +
                " " +
                this.state.notificationList[i].created_by.last_name}
            </div>
            <div className="clear"></div>
          </div>
        );
      }
    }
    for (let i = 0; i < this.state.orderList.length; i++) {
      if (this.state.orderList[i].status === 2) {
        data.push(
          <tr key={this.state.orderList[i]._id}>
            {<td>{i + 1}</td>}
            <td>{this.state.orderList[i].items[0].course.course_name}</td>
            <td>{this.state.orderList[i]["order_date"].split("").splice(0, 10).join("")}</td>
            <td>{this.state.orderList[i]["final_price"]}</td>
            <td>
              <a style={{ cursor: "pointer" }} onClick={this.handleDownload.bind(this, this.state.orderList[i]._id)}>
                {utils.intlLang("download")}
              </a>
            </td>
          </tr>
        );
      } else {
        data.push(
          <tr key={this.state.orderList[i]._id}>
            {<td>{i + 1}</td>}
            <td>{this.state.orderList[i].items[0].course.course_name}</td>
            <td>{this.state.orderList[i]["order_date"].split("").splice(0, 10).join("")}</td>
            <td>{this.state.orderList[i]["final_price"]}</td>
            <td></td>
          </tr>
        );
      }
    }
    let courseData = [];
    let expiredcourseData = [];
    let courseImage = "";
    let userImage = "";
    if (this.userData) {
      for (let j = 0; j < this.userData.length; j++) {
        if (this.userData[j].courses.length) {
          for (let i = 0; i < this.userData[j].courses.length; i++) {
            let count = 0;
            for (let k = 0; k < this.state.likescount.length; k++) {
              if (
                this.state.likescount[k].course === this.userData[j].courses[i].course._id &&
                this.state.likescount[k].status === true
              ) {
                count = count + 1;
              }
            }
            // if (this.userData[j].courses[i].course.course_type == "live_session") {
            //   const period = this.userData[j].courses[i].course.period;
            //   const newDate = moment(this.userData[j].courses[i].course.end_date).add(period, "days").valueOf();
            //   this.userData[j].courses[i].end_time = new Date(newDate);
            // }
            if (
              this.userData[j].courses[i].is_active === true &&
              new Date(this.userData[j].courses[i].end_time).getTime() > new Date().getTime()
            ) {
              if (
                !this.userData[j].courses[i].course.created_by ||
                this.userData[j].courses[i].course.created_by.image == ""
              ) {
                userImage = <img src="/assets/img/team/2.jpg" alt="Thumb" />;
              } else {
                userImage = <img src={this.userData[j].courses[i].course.created_by.image} alt="Thumb" />;
              }

              if (this.userData[j].courses[i].course.image == "") {
                courseImage = <img className="imagepop" src="/assets/img/courses/6.jpg" alt="Thumb" />;
              } else {
                courseImage = <img className="imagepop" src={this.userData[j].courses[i].course.image} alt="Thumb" />;
              }
              if (this.userData[j].courses[i].course.description.length >= 39) {
                this.userData[j].courses[i].course.description =
                  this.userData[j].courses[i].course.description.substring(0, 39) + "...";
              }
              // if (this.userData[j].courses[i].course["name"].length >= 24) {
              //   this.userData[j].courses[i].course["name"] =
              //     this.userData[j].courses[i].course.name.substring(0, 24) + "...";
              // }

              this.userData[j].courses[i].course["name"] = this.userData[j].courses[i].course.name

              courseData.push(
                <div key={"divcol-" + j + "" + i} className="col-md-6 col-sm-6 equal-height" style={{ height: "540px", marginBottom: "5px" }}>
                  <div className="item">
                    <Link
                      to={{
                        pathname:
                          "/user/course/" + this.userData[j].courses[i].course._id + "/" + window.user.data.first_name,
                      }}
                    >
                      <div className="thumb">{courseImage}</div>
                    </Link>
                    <div className="info">
                      <div className="author-info">
                        <Link
                          to={{
                            pathname:
                              "/user/course/" +
                              this.userData[j].courses[i].course._id +
                              "/" +
                              window.user.data.first_name,
                          }}
                        >
                          <div className="thumb">{userImage}</div>
                        </Link>
                        <div className="others">
                          <div className="createdby">
                            <Link
                              to={{
                                pathname:
                                  "/user/course/" +
                                  this.userData[j].courses[i].course._id +
                                  "/" +
                                  window.user.data.first_name,
                              }}
                            >
                              {this.userData[j].courses[i].course.created_by
                                ? this.userData[j].courses[i].course.created_by.first_name
                                : ""}
                            </Link>
                          </div>
                          <div className="rating usrprofilerating">
                            <span onClick={this.countLikes.bind(this, this.userData[j].courses[i].course._id)}>
                              <i className="far fa-thumbs-up fa-2x"></i>
                            </span>

                            {count}
                            <Link
                              to={{
                                pathname:
                                  "/user/course/" +
                                  this.userData[j].courses[i].course._id +
                                  "/" +
                                  window.user.data.first_name,
                              }}
                            >
                              <div className="price-btn" style={{ display: "block" }}>
                                {currencyVal} {this.userData[j].courses[i].course.price}
                              </div>

                              {this.courseSuccessResult(this.userData[j].courses[i].course, this.state.courseResult)}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <Link
                        to={{
                          pathname:
                            "/user/course/" + this.userData[j].courses[i].course._id + "/" + window.user.data.first_name,
                        }}
                      >
                        <h4>{this.userData[j].courses[i].course.name}</h4>
                      </Link>

                      <Link
                        to={{
                          pathname:
                            "/user/course/" + this.userData[j].courses[i].course._id + "/" + window.user.data.first_name,
                        }}
                      >
                        <div className="summary-descr">
                          {ReactHtmlParser(this.userData[j].courses[i].course.description)}
                        </div>
                        <div className="course-expire-date">
                          {this.lmsResultCrsMapping(this.userData[j].courses[i].course, this.state.courseResult)
                            ? null
                            : `${utils.intlLang("Expires On :")} ${moment(this.userData[j].courses[i].end_time).format("DD-MM-YYYY")}`}
                        </div>
                      </Link>
                      <div className="row">
                        <div className="col-sm-5">
                          {/* this.userData[j].courses[i].course.end_date===null && */}
                          {/* {  this.userData[j].courses[i].course.section.length >0 ? ( */}
                          {this.userData[j].courses[i].is_course_completed === true ? (
                            <div className="user-profile-btn">
                              <button
                                style={{ fontSize: "12px" }}
                                type="button"
                                className="btn btn-dark effect "
                                id="button1"
                                onClick={() => {
                                  this.props.history.push({
                                    pathname: `/course/result/${this.userData[j].courses[i].course._id}`,
                                    search: "",
                                    state: { is_course_completed: this.userData[j].courses[i].is_course_completed },
                                  });
                                }}
                              >
                                <i className="fas fa-camera"></i> {utils.intlLang("view Result")}
                            </button>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {this.userData[j].courses[i].course.course_type == "online" && (
                        <div className="courseCertfication">
                          {utils.intlLang("Score")} :
                          {this.lmsResultCrsMapping(this.userData[j].courses[i].course, this.state.courseResult) ||
                            " no  score yet. "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            } else if (
              this.userData[j].courses[i].is_active === false ||
              new Date(this.userData[j].courses[i].end_time).getTime() < new Date().getTime()
            ) {
              if (
                !this.userData[j].courses[i].course.created_by ||
                this.userData[j].courses[i].course.created_by.image == ""
              ) {
                userImage = <img src="/assets/img/team/2.jpg" alt="Thumb" />;
              } else {
                userImage = <img src={this.userData[j].courses[i].course.created_by.image} alt="Thumb" />;
              }

              if (this.userData[j].courses[i].course.image == "") {
                courseImage = <img className="imagepop" src="/assets/img/courses/6.jpg" alt="Thumb" />;
              } else {
                courseImage = <img className="imagepop" src={this.userData[j].courses[i].course.image} alt="Thumb" />;
              }
              expiredcourseData.push(
                <div
                  key={"divcol-" + j + "" + i}
                  className="col-md-6 col-sm-6 equal-height"
                  style={{ opacity: "0.6", height: "540px", marginBottom: "5px" }}
                >
                  <div className="item">
                    <div className="thumb">{courseImage}</div>
                    <div className="info">
                      <div className="author-info">
                        <div className="thumb">{userImage}</div>
                        <div className="others">
                          {this.userData[j].courses[i].course.created_by
                            ? this.userData[j].courses[i].course.created_by.first_name
                            : ""}
                          <div className="rating">
                            <span onClick={this.countLikes.bind(this, this.userData[j].courses[i].course._id)}>
                              <i className="far fa-thumbs-up fa-2x"></i>
                            </span>

                            {count}
                            <div className="price-btn" style={{ display: "block" }}>
                              <>{currencyVal} </>
                              {this.userData[j].courses[i].course.price}
                            </div>
                          </div>
                        </div>
                      </div>

                      <h4>{this.userData[j].courses[i].course.name}</h4>

                      <div className="summary-descr">
                        {ReactHtmlParser(this.userData[j].courses[i].course.description)}
                        <div className="course-expire-date">
                          {utils.intlLang("Expires On :")} {moment(this.userData[j].courses[i].end_time).format("DD-MM-YYYY")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          }
        }
        else {
          courseData = (
            <div className="text-center">
              <strong>{utils.intlLang("No courses linked to your account")}, <Link to='/course'>{utils.intlLang("please click here to redirect to course page")}</Link></strong>
            </div>
          )
        }
      }
    }
    let detail = null;
    if (this.state.user) {
      detail = (
        <>
          <h3>Profile</h3>
          <hr className="mb-3" style={{ marginBottom: "20px" }} />
          {this.showMessage()}
          <UserForm student={this.state.student} handleUserName={this.props.handlerUserName} />
          <br/>
          <div className="update-pass" >
            <h4>{utils.intlLang("Change Password")}</h4>
            <PasswordChanged student={this.state.student} handleUserName={this.props.handlerUserName} />
          </div>
        </>
      );
    }
    return (
      <>
        {detail}
      </>
    );
  }
}
