import React, { Component } from "react";
import { Formik, ErrorMessage, FieldArray } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-bootstrap/Form";
import FormService from "../../services/formService";
import UserService from '../../services/userService'
export default class form extends Component {
  constructor(props) {
    super(props);
    this.formId = this.props.match.params.formId;
    this.courseId = this.props.match.params.courseId;
    this.user_id = window.user.data._id;
    this.state = {
      formList: [],
      //questions: [],
      arrays: [],
    };

    this.FormServ = new FormService();
    this.userServ = new UserService();
  }

  componentDidMount() {
    this.getFormList();
  }

  getFormList() {
    this.setState({ loading: true });

    this.FormServ.getListForms(this.formId).then(
      (response) => {
        let formData = response.data;
        this.setState({ formList: formData, loading: false });
      },
      (error) => {
        this.setState({ formList: [], loading: false });
      }
    );
  }

  submitSection = (values) => {
    values.questions.map((qtn,idx) => {
      qtn.user_answers = values.user_answers[idx]
    })
    
    let obj = {
      formData: values.questions,      
      userId: window.user.data._id, 
      formId: this.formId,
      courseId: this.courseId
     };
    this.FormServ.SubmitFormTest(obj).then(
      (response) => {
        if(response){
          let objs = {
            "courseId": this.courseId,
            "formId": this.formId,
            "userId": this.user_id
          }
          

          this.userServ.updateFormId(objs).then(
            (response) => {
            }
          ).catch((err) => {toast.error(err.toString())})
        }
        toast.success("Form data save successfully ");
        this.props.history.push("/user-course")
        // let formData = response.data;
        // this.setState({ formList: formData, loading: false });
      },
      (error) => {
        toast.error(error.toString());
        this.setState({ formList: [], loading: false });
      }
    ).catch((err) => { toast.error(err.toString()); console.log(err)})
  };

  render() {
    
    return (
      <div className="container">
        <Formik
          initialValues={this.state.formList}
          enableReinitialize={true}
          onSubmit={this.submitSection}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            errors,
            setFieldValue,
            resetForm,
            isSubmitting,
          }) => {
            return (
              <Form onSubmit={handleSubmit} style={{ marginTop: "20px" }}>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h1 className="panel-title">Form List</h1>
                  </div>
                  <div className="panel-body">
                    <ul>
                      {values?.questions?.map((qtns, index) => {
                        return (
                          <>
                            <Row>
                              {qtns?.answer_type === "single_mcq" ? (
                                <Col sm={12} md={12}>
                                  <p>{index+1}. {qtns?.question}</p>
                                  {qtns?.given_options?.map((options, idx) => {
                                    return (
                                      <>
                                        <div className="col-md-12" style={{ textAlign: "justify", minHeight:"1px", }}>
                                          <li>
                                            <input
                                              style={{ display: "none" }}
                                              type="text"
                                              value={values?.question}
                                              name="question"
                                            />

                                            <Form.Check
                                              style={{ display: "inline-grid" }}
                                              type="radio"
                                              value={options}
                                              onChange={(e) => {
                                                handleChange(e);
                                              }}
                                              name={`user_answers.${index}`}
                                              // disabled={finalTimeUp || timeupMessage}
                                            />
                                            <span className="radio-button">
                                              {options}
                                            </span>
                                          </li>
                                        </div>
                                      </>
                                    );
                                  })}
                                </Col>
                              ) : null}
                            </Row>
                            <Row>
                              {qtns?.answer_type === "multiple_mcq" ? (
                                <Col sm={12} md={12}>
                                  <p>{index+1}. {qtns?.question}</p>
                                  {qtns?.given_options?.map((options, idx) => {
                                    return (
                                      <>
                                        <div className="col-md-12">
                                          <li>
                                            <input
                                              style={{ display: "none" }}
                                              type="text"
                                              value={values?.question}
                                              name="question"
                                            />

                                            <Form.Check
                                              style={{ display: "inline-grid" }}
                                              type="checkbox"
                                              value={options}
                                              onChange={(e) => {
                                                handleChange(e);
                                              }}
                                              name={`user_answers.${index}`}
                                              // disabled={finalTimeUp || timeupMessage}
                                            />
                                            <span className="radio-button">
                                              {options}
                                            </span>
                                          </li>
                                        </div>
                                      </>
                                    );
                                  })}
                                </Col>
                              ) : null}
                            </Row>
                            <Row>
                              {qtns?.answer_type === "true/false" ? (
                                <Col sm={12} md={12}>
                                  <p>{index+1}. {qtns?.question}</p>
                                  {qtns?.given_options?.map((options) => {
                                    return (
                                      <>
                                        <div className="col-md-3">
                                          <li>
                                            <input
                                              style={{ display: "none" }}
                                              type="text"
                                              value={values?.question}
                                              name="question"
                                            />

                                            <Form.Check
                                              style={{ display: "inline-grid" }}
                                              type="radio"
                                              value={options}
                                              onChange={(e) => {
                                                handleChange(e);
                                              }}
                                              name={`user_answers.${index}`}
                                              // disabled={finalTimeUp || timeupMessage}
                                            />
                                            <span className="radio-button">
                                              {options}
                                            </span>
                                          </li>
                                        </div>
                                      </>
                                    );
                                  })}
                                </Col>
                              ) : null}
                            </Row>
                            <Row>
                              {qtns?.answer_type === "text" ? (
                                <Col sm={12} md={12}>
                                  <p>{index+1}. {qtns?.question}</p>
                                  {qtns?.given_options?.map((options) => {
                                    return (
                                      <>
                                        <div className="col-md-8" style={{marginBottom: "20px"}}>
                                          <li>
                                          <Form.Control
                                            // type="text"
                                            as="textarea"
                                            style={{ minHeight: '100px' }}
                                            onChange={(e) => {
                                              handleChange(e);
                                            }}
                                            name={`user_answers.${index}`}
                                            placeholder="Answer"
                                            // disabled={timeupMessage}
                                          />
                                          </li>
                                        </div>
                                      </>
                                    );
                                  })}
                                </Col>
                              ) : null}
                            </Row>
                          </>
                        );
                      })}
                    </ul>
                    <div className="col-md-12 col-md-offset-5 mt-4">
                      <div className="row">
                        <button type="submit" className="btn btn-success ">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        <ToastContainer />
      </div>
    );
  }
}
 