import React from "react";
import utils from '../../../utils/util'

function resultScreen(props) {
  return (
    <div className="result">
      {utils.intlLang("You got ")}{props.marksObtained} {utils.intlLang("out of")} {props.totalMarks} {utils.intlLang("in")} <strong>{props.quizzeName} </strong> {utils.intlLang("test")}
    </div>
  );
}

export default resultScreen;
