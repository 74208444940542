import util from '../utils/util';

export default  class FormService{
    getListForms(id){
        return util.sendApiRequest("/form/form-list/"+id,"GET",true).then(
            (response)=>{
                return response;
            },
            (error)=>{
                throw new Error(error);
            },
        );
    }

    SubmitFormTest(data) {
        return util.sendApiRequest("/form/submit-form", "POST", true, data).then(
          (response) => {
            return response;
          },
          (error) => {
            throw new Error(error);
          }
        );
      }
    
}