import React from "react";
import Pagination from "react-bootstrap/Pagination";
import { Link } from "react-router-dom";
import UserService from "../../services/userService";
import lmsService from "../../services/lmsService";
import OrderService from "../../services/orderService";
import Banner from "../Banner/banner";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import LikesCount from "../../services/likeService";
import Notification from "../../services/notificationService";
import setting from "../../services/settingService";
import { FeedBackForm } from "./FeedBackForm";
import UserForm from "./UserForm";
import PasswordChanged from "./PasswordChanged";
import Message from "./Message";
import Livesessiondetails from "./liveSessionList"
import UserMenu from "../../components/userMenu"
import utils from '../../utils/util';
import { Modal,Button, Form, DropdownButton, Dropdown } from "react-bootstrap";
const axios = require("axios");
export default class CompleteAssignment extends React.Component {
  constructor(props) {
    super(props);
    this.user_id = window.user.data._id;
    this.orderuser_id = window.user.data._id;
    this.image = "";
    this.backref = null;
    this.userData = [];
    this.search = { start: 0, perPage: 10, searchTxt: "", searchField: "" };
    this.state = {
      totalCount: 0,
      showModal: false,
      assignmentComment: '',
      courseResult: [{ result: {}, courseId: "", status: "" }],
      file: null,
      previewImage: null,
      redirect: false,
      loading: false,
      scrollPosition: 400,
      sucessMessage: "",
      errorMessage: "",
      user: "",
      profileImg: "",
      orderList: [],
      userCourse: [],
      likescount: [],
      notificationList: [],
      assignmentList: [],
      completeassignmentList: [],
      settingList: [],
    };
    this.userServ = new UserService();
    this.settingserv = new setting();

    if (this.user_id) {
      this.userServ.getUser(this.user_id, true).then(
        (response) => {
          if (response.data.dob) {
            response.data.dob = response.data.dob.split("").splice(0, 10).join("");
          }
          this.userData.push(response.data);
          let filterArry = response.lmsResult.map((el) => {
            let result = el.statement.result;
            let status = el.statement.verb.display["en-US"];
            let courseId = el.statement.object.id.split("//").slice(1).join("");
            return { result, status, courseId };
          });
          this.setState({
            userCourse: response.data,
            profileImg: response.data.image,
            courseResult: filterArry,
          });

          let tempUser = JSON.parse(JSON.stringify(response.data));
          this.setState({ student: tempUser });

          this.setState({ user: response.data });
        },
        (error) => {
          alert(utils.intlLang("Oops, something went wrong not able to fetch user details."));
        }
      );
    }
  }
  componentDidMount() {
    window.scrollTo(0, 100);
    this.getAssignmentList();
    this.completedAssignmentList();
  }

  getAssignmentList() {
    this.userServ.getAssignmentList(this.search.start, 10000, this.user_id).then(
      (response) => {
        console.log('response',response)
        this.setState({ assignmentList: response.data });
      },
      (error) => {
        this.setState({ assignmentList: [], totalcount: 0 });
      }
    );
  }

  completedAssignmentList() {
    this.userServ.completedAssignmentList(this.search.start, 10000, this.user_id).then(
      (response) => {
        console.log('response',response)
        this.setState({ completeassignmentList: response.data });
      },
      (error) => {
        this.setState({ completeassignmentList: [], totalcount: 0 });
      }
    );
  }
  handlePaging(e) {
    if (e.target.text) {
      this.search.start = parseInt(e.target.text) * this.search.perPage - this.search.perPage;
      this.getNotificationList();
    }
  }

  scrollUp(amountToScroll) {
    this.setState({
      scrollPosition: this.state.scrollPosition + amountToScroll,
    });
    window.scrollTo(0, this.state.scrollPosition);
  }

  showMessage() {
    if (this.state.successMessage) {
      return <div className="alert alert-success">{this.state.successMessage}</div>;
    }
    if (this.state.errorMessage) {
      return <div className="alert alert-danger">{this.state.errorMessage}</div>;
    }
  }
  

  courseSuccessResult(course, courseResult) {
    for (let cr of courseResult) {
      if (cr.courseId == course._id) {
        return <div className={cr.result.success ? "success" : "failed"}>{cr.status}</div>;
      }
    }
  }
  showComment(comment)
  {
    this.setState({assignmentComment:comment,showModal: true })
  }
  handleClose() {
    this.setState({ showModal: false });
  }
  render() {
    if (this.props.location?.state?.prevPath == "/user/course") {
      this.backref && this.backref.click();
    }
    let user2ndaddr = "";
    let user3rdaddr = "";
    let currencyVal = "";
    for (let i = 0; i < this.state.settingList.length; i++) {
      if (this.state.settingList[i].name === "currencySymbol") {
        currencyVal = this.state.settingList[i].value;
      }
    }
    // let active = Math.ceil((this.search.start + 1) / this.search.perPage);
    // let pages = Math.ceil(this.state.totalCount / this.search.perPage);
    // let items = [];
    // for (let number = 1; number <= pages; number++) {
    //   items.push(
    //     <Pagination.Item key={number} onClick={this.handlePaging.bind(this)} active={number === active}>
    //       {number}
    //     </Pagination.Item>
    //   );
    // }
    if (this.state.user) {
      user2ndaddr = this.state.user.address_2 === "undefined" ? "" : this.state.user.address_2;
      user3rdaddr = this.state.user.address_3 === "undefined" ? "" : this.state.user.address_3;
    }
    if (this.state.redirect) {
      window.location.reload(false);
      this.userServ.logout();
      return <Redirect to="/"></Redirect>;
    }
    let data=[];
    if(this.state.assignmentList)
    {
        
        this.state.assignmentList.map((el,index)=>{
       
            if ( el.is_active === true) {
            
                    if(el.assignment)
                    {
                      el.assignment.map(el1=>{
                          if(el1.is_publish)
                          {
                            let found = this.state.completeassignmentList.findIndex(function(assng, index) {
                              if(assng.assignmentId == el1.assignment_id?._id && assng.couresId == el._id)
                                return true;
                            });
                               
                              if(found!=-1)
                              {
                                  data.push(
                                      <tr key={el._id}>
                                      <td>
                                         <Link
                                          to={{
                                              pathname:
                                              "/user/course/" + el._id + "/" + window.user.data.first_name,
                                          }}
                                          >
                                          {el.name}
                                          </Link>
                                      </td>
                                      <td>
                                          
                                          <Link
                                          to={{
                                              pathname:
                                              "/assignment/" + el._id + "/" +el1.assignment_id?._id,
                                          }}
                                          >
                                          {el1.title}
                                          </Link>
  
                                          </td>
                                          <td>
                                          {this.state.completeassignmentList[found].marks_allocated}
                                            
                                         </td>
                                          <td>
                                            <a style={{ cursor: "pointer" }} onClick={(e)=>this.showComment(this.state.completeassignmentList[found].description)}>
                                            View Comment
                                            </a>
                                            
                                         </td>
                                    </tr>
                                  )  
                              }
                              
                          }
                         
                      }) 
                     
                    }
              //     }
              //  }
                  
                
               
              }
              else
              {
                console.log('else',new Date(el.end_date).getTime(),new Date().getTime())
              }
           
        })
    }
    let detail = null;
    if (this.state.user) {

      detail = (
        <>
          <div className="">
            <div className="row">
              <h3>{utils.intlLang("Completed Assignment List")}</h3>
              <hr className="mb-3" style={{ marginBottom: "20px" }} />
              
            </div>
          </div>
        </>
      );
    }
    return (
      <>
      <div>{detail}</div>
       <div>
       <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{utils.intlLang("Course Name")}</th>
                    <th>{utils.intlLang("Assignment Name")}</th>
                    <th>{utils.intlLang("Allocated Marks")}</th>
                    <th>{utils.intlLang("Comment")}</th>
                    
                  </tr>
                </thead>
                <tbody>{data}</tbody>
              </table>
            </div>
            <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Comments</Modal.Title>
          </Modal.Header>
          <Modal.Body>
           <p>
             {this.state.assignmentComment}
           </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="button"
              onClick={this.handleClose.bind(this)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      
       </div>
      </>
    );
  }
}
