import React from "react";
import userservice from "../../services/userService";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import utils from '../../utils/util';
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { user: "" };
    this.state = {
      username: "",
      password: "",
      loading: false,
      redirect: false,
      messageType: "",
      sucessMessage: "",
      error: "",
      user: null,
    };
    this.userv = new userservice();
    this.props.handlerLoginParent(false);
  }

  submitLogin(e) {
    e.preventDefault();
    this.setState({ loading: true });
    this.userv.login(this.state.username, this.state.password).then(
      (response) => {
        this.props.handlerUserName(response);
        this.props.handlerLoginParent(true);
        this.setState({ user: response.data, loading: false, redirect: true, successMessage: "Successfully Login" });
      },
      (error) => {
        if (error.toString().length !== 0 && error.toString() === "Error: [{}]") {
          this.setState({ error: "invalid User" });
        } else if (error.toString() === "Error: Password or Username is incorrect") {
          this.setState({ loading: false, redirect: false, error: "Password or Username is incorrect" });
        }
        toast.error("Password or Username is incorrect ");
        this.props.handlerLoginParent(false);
      }
    );
  }

  handleUsernameChange(event) {
    this.setState({ username: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }
  render() {
    const { user } = this.state;
    if (this.state.redirect === true) {
      if (user.is_action_for_password) {
        this.props.history.push("/change-password");
      } else {
        this.props.history.push("/user/profile");
      }
    }

    return (
      <div>
        <div className="login-area default-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <form action="#" id="login-form" className="white-popup-block" onSubmit={this.submitLogin.bind(this)}>
                  <div className="col-md-8 login-custom col-centered">
                    <h4>{utils.intlLang("login to your registered account!")}</h4>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="form-group">
                          <label htmlFor="inputUsername" className="sr-only">
                            {utils.intlLang("Email")}
                          </label>
                          <input
                            type="email"
                            id="inputUsername"
                            className="form-control"
                            placeholder={utils.intlLang("Email")}
                            onChange={this.handleUsernameChange.bind(this)}
                            autoComplete="off"
                            required
                            autoFocus
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="form-group">
                          <label htmlFor="inputPassword" className="sr-only">
                            {utils.intlLang("Password")}
                          </label>
                          <input
                            type="password"
                            id="inputPassword"
                            className="form-control"
                            placeholder={utils.intlLang("Password")}
                            onChange={this.handlePasswordChange.bind(this)}
                            autoComplete="new-password"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <label htmlFor="login-remember">
                          <input type="checkbox" id="login-remember" />
                          {utils.intlLang("Remember Me")}
                        </label>

                        <Link title="Lost Password" to={{ pathname: "/forgetpassword" }} className="lost-pass-link">
                        {utils.intlLang("Forgot password?")}
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <button type="submit">{utils.intlLang("Login")}</button>
                        <div className="errormsg">{this.state.error}</div>
                      </div>
                    </div>
                    <p className="link-bottom">
                      {utils.intlLang("Not a member yet?")} <Link to={{ pathname: "/signup" }}>{utils.intlLang("Register now")}</Link>
                    </p>
                  </div>
                </form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
