import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CourseService from "../../services/courseService";
import userServices from "../../services/userService";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import LikesCount from "../../services/likeService";
import moment from "moment";
import setting from "../../services/settingService";
import utils from '../../utils/util';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default class PopularCourse extends React.Component {
  constructor(props) {
    super(props);
    this.user = window.user;
    this.stud = window.user ? window.user?.data?._id : "";
    this.state = { popularCourseList: [], likescount: [], successMessage: false, settingList: [] };
    this.courseServ = new CourseService();
    this.likesServ = new LikesCount();
    this.userserv = new userServices();
    this.settingserv = new setting();
  }
  componentDidMount() {
    this.getPopularCourseList();
    this.countLikes();
    this.getSettingVal();
    this.listAllLikes();
    if (this.stud) {
      this.getStudent();
    }
  }

  createOwlCarousel() {
    setTimeout(() => {
      window.jQuery(".popular-courses-carousel").owlCarousel({
        loop: false,
        margin: 30,
        nav: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        dots: false,
        autoplay: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      });
    }, 600);
  }
  getPopularCourseList() {
    this.courseServ.popularCourseFrontend().then(
      (response) => {
        this.setState({ popularCourseList: response.data });
        this.createOwlCarousel();
      },
      (error) => {
        this.setState({ popularCourseList: [] });
      }
    );
  }
  getSettingVal() {
    this.settingserv.listSetting(0, 10000000).then(
      (response) => {
        this.setState({ settingList: response.data });
      },
      (error) => {
        this.setState({ settingList: [] });
      }
    );
  }
  countLikes(id) {
    this.likesServ.likesCount(id).then(
      (response) => {
        if (response.data === null) {
          response.data = [];
        } else {
          this.listAllLikes();
        }
      },
      (error) => {
      }
    );
  }
  getStudent() {
    this.userserv.getUser(this.stud).then(
      (response) => {
        this.stud = response.data;
      },
      (error) => {
        alert(utils.intlLang("Oops, something went wrong not able to fetch course  details."));
      }
    );
  }
  PlacedOrderZeroPrice(crs) {
    let { _id, is_active, period } = crs;
    let day = moment().add(period, "days").valueOf();
    let courses = [...this.stud.courses, { course: _id, is_active, end_time: day, is_course_completed: false }];
    this.stud.courses = courses;
    this.stud.selectedCourseId = _id;
    this.userserv.editUser(this.stud).then(
      (response) => {
        window.scroll(800, 800);
        toast.success(utils.intlLang("You have successfully enrolled for this course"))
        this.setState({ successMessage: true });
      },
      (error) => {
        if(error){
          toast.error(error.message.replace("Error:",""))
        }  
        this.setState({ successMessage: false });
      }
    );
  }

  listAllLikes() {
    this.likesServ.listLike().then(
      (response) => {
        this.setState({
          likescount: response.data,
        });
      },
      (error) => {
        this.setState({
          likescount: [],
        });
      }
    );
  }

  render() {
    let data = [];
    let detail = "";
    let enroll = "";
    let userImage = "";
    let currencyVal = "";
    for (let i = 0; i < this.state.settingList.length; i++) {
      if (this.state.settingList[i].name === "currencySymbol") {
        currencyVal = this.state.settingList[i].value;
      }
    }
    for (let i = 0; i < this.state.popularCourseList.length; i++) {
      let count = 0;
      for (let j = 0; j < this.state.likescount.length; j++) {
        if (
          this.state.likescount[j].course === this.state.popularCourseList[i]._id &&
          this.state.likescount[j].status === true
        ) {
          count = count + 1;
        }
      }
      if (this.state.popularCourseList[i].is_active === true && this.state.popularCourseList[i].hide_public == false) {
        if (this.state.popularCourseList[i].created_by && !this.state.popularCourseList[i].created_by.image) {
          userImage = <img src="/assets/img/team/2.jpg" alt="Thumb" />;
        } else {
          userImage = (
            <img
              src={
                this.state.popularCourseList &&
                this.state.popularCourseList[i].created_by &&
                this.state.popularCourseList[i].created_by.image
              }
              alt="Thumb"
            />
          );
        }
        if (this.user) {
          if (+this.state.popularCourseList[i].price == 0) {
            enroll = (
              <div
                className="btn btn-theme effect btn-sm"
                onClick={() => {
                  this.PlacedOrderZeroPrice(this.state.popularCourseList[i]);
                }}
              >
                {" "}
                <i className="fas fa-chart-bar"></i> {utils.intlLang("Enrol")}
              </div>
            );
          } else {
            // enroll = (
            //   <Link
            //     className="btn btn-theme effect btn-sm"
            //     to={{ pathname: "/order/" + this.state.popularCourseList[i]._id }}
            //   >
            //     {" "}
            //     <i className="fas fa-chart-bar"></i> {utils.intlLang("Enrol")}
            //   </Link>
            // );
            enroll = (
              <div
                className="btn btn-theme effect btn-sm"
                onClick={() => {
                  this.PlacedOrderZeroPrice(this.state.popularCourseList[i]);
                }}
              >
                {" "}
                <i className="fas fa-chart-bar"></i> {utils.intlLang("Enrol")}
              </div>
            );
          }
        } else if (!this.user) {
          enroll = (
            <Link className="btn btn-theme effect btn-sm" to={{ pathname: "/login/" }}>
              {" "}
              <i className="fas fa-chart-bar"></i> {utils.intlLang("Enrol")}
            </Link>
          );
        }
        if (this.state.popularCourseList[i].image == "") {
          detail = <img className="imagepop" src="/assets/img/courses/6.jpg" alt="Thumb" />;
        } else {
          detail = <img className="imagepop" src={this.state.popularCourseList[i].image} alt="Thumb" />;
        }
        // if (this.state.popularCourseList[i].name.length >= 24) {
        //   this.state.popularCourseList[i].name = this.state.popularCourseList[i].name.substring(0, 24) + "...";
        // }

        this.state.popularCourseList[i].name = this.state.popularCourseList[i].name

        if (this.state.popularCourseList[i].description.length >= 49) {
          this.state.popularCourseList[i].description_short =
            this.state.popularCourseList[i].description.substring(0, 49) + "...";
        }
        else {
          this.state.popularCourseList[i].description_short = this.state.popularCourseList[i].description
        }
        data.push(
          <div className="item" key={i + 1}>
            <div className="thumb">
              <Link
                to={{
                  pathname: "/details/" + this.state.popularCourseList[i]._id,
                }}
              >
                {detail}
              </Link>
              <div className="overlay">{enroll}</div>
            </div>
            <div className="info">
              <div className="author-info">
                <div className="thumb">
                  <Link
                    to={{
                      pathname: "/details/" + this.state.popularCourseList[i]._id,
                    }}
                  >
                    {userImage}
                  </Link>
                </div>
                <div className="others">
                  <Link
                    to={{
                      pathname: "/details/" + this.state.popularCourseList[i]._id,
                    }}
                  >
                    {this.state.popularCourseList[i].created_by
                      ? this.state.popularCourseList[i].created_by.first_name
                      : ""}
                  </Link>
                  <div className="rating">
                    <span onClick={this.countLikes.bind(this, this.state.popularCourseList[i]._id)}>
                      <i className="far fa-thumbs-up fa-2x"></i>
                    </span>

                    {count}
                    {/* <div className="price-btn">
                      <>{currencyVal ? currencyVal : ""} </>
                      {this.state.popularCourseList[i].price}
                    </div> */}
                  </div>
                </div>
              </div>
              <h4>
                <Link
                  to={{
                    pathname: "/details/" + this.state.popularCourseList[i]._id,
                  }}
                >
                  {this.state.popularCourseList[i].name}
                </Link>
              </h4>
              <div className="summary-descr">
              <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      {this.state.popularCourseList[i].description}
                    </Tooltip>
                  )}
                  placement="bottom"
              >
                <Link
                  to={{
                    pathname: "/details/" + this.state.popularCourseList[i]._id,
                  }}
                >
                  {ReactHtmlParser(this.state.popularCourseList[i].description_short)}
                </Link>
                </OverlayTrigger>

              </div>
            </div>
          </div>
        );
      }
    }
    return (
      <>
      <div className="popular-courses circle carousel-shadow bg-gray default-padding">
        <div className="container">
          <div className="row">
            {/* {this.state.successMessage && <div className="alert alert-success">{utils.intlLang("Course Enrolled Successfull")}</div>} */}
            <div className="site-heading text-center">
              <div className="col-md-8 col-md-offset-2">
                <h2>{utils.intlLang("Popular Courses")}</h2>
                <p>
                  {utils.intlLang("Discourse assurance estimable applauded to so. Him everything melancholy uncommonly but solicitude inhabiting projection off. Connection stimulated estimating excellence an to impression.")}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="popular-courses-items bottom-price popular-courses-carousel owl-carousel owl-theme">
                {data}
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      </>
    );
  }
}
