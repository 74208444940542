import React from "react";
import moment from "moment";
import utils from "../../utils/util";

//create the date arr range between the given date range
const dateRangeCreation = function getDates({
  startDate,
  endDate: stopDate,
  startTime,
  endTime,
}) {
  var dateArray = [];
  var currentDate = moment(startDate);
  var stopDate = moment(stopDate);
  while (currentDate <= stopDate) {
    dateArray.push({
      formatedTime: `${moment(currentDate).format("LL")} at ${moment(
        startTime
      ).format("LT")} to ${moment(endTime).format("LT")} `,
      actualTime: currentDate,
    });
    currentDate = moment(currentDate).add(1, "days");
  }
  return dateArray;
};
//custom date
const formatcustomdates = function getDates({
  customDates = [],
  startTime,
  endTime,
}) {
  let sortedcstmDates = customDates.sort();
  return sortedcstmDates.map((el) => {
    return {
      formatedTime: `${moment(el).format("LL")} at ${moment(startTime).format(
        "LT"
      )} to ${moment(endTime).format("LT")} `,
      actualTime: el,
    };
  });
};

function LiveSessionCourse({
  startDate,
  endDate,
  startTime,
  endTime,
  type,
  weekVal = [],
  selectedDays = [],
  displayAccordian,
}) {
  let dailyEventList = dateRangeCreation({
    startDate,
    endDate,
    startTime,
    endTime,
  });
  let weekDaysEventList = dailyEventList
    .map((el) => {
      if (weekVal.includes(el.actualTime.format("ddd"))) {
        return {
          formatedTime: `${el.actualTime.format("LL")} at ${moment(
            startTime
          ).format("LT")} to ${moment(endTime).format("LT")} `,
          actualTime: el.actualTime,
        };
      }
    })
    .filter(Boolean);

  let customEventList = formatcustomdates({
    customDates: selectedDays,
    startTime,
    endTime,
  });
  function showEvent(type) {
    let data = null;
    switch (type) {
      case "daily":
        data = dailyEventList.map((el) => {
          return (
            <p key={el.formatedTime}>
              <div class="item name">
                {moment().isSame(el.actualTime, "days") ? (
                  <div>{el.formatedTime}</div>
                ) : (
                  el.formatedTime
                )}
              </div>
              <div class="item title"></div>
            </p>
          );
        });
        break;
      case "weekly":
        data = weekDaysEventList.map((el) => {
          return (
            <p key={el.formatedTime}>
              <div class="item name">
                {moment().isSame(el.actualTime, "days") ? (
                  <div>{el.formatedTime}</div>
                ) : (
                  el.formatedTime
                )}
              </div>
              <div class="item title"></div>
            </p>
          );
        });
        break;
      case "custom":
        data = customEventList.map((el) => {
          return (
            <p key={el.formatedTime}>
              <div class="item name">
                {moment().isSame(el.actualTime, "days") ? (
                  <div>{el.formatedTime}</div>
                ) : (
                  el.formatedTime
                )}
              </div>
              <div class="item title"></div>
            </p>
          );
        });
        break;

      default:
        data = `${utils.intlLang("loading")}...`;
        break;
    }
    return data;
  }
  //show from and two date
  function showfromtoEvent(type) {
    let data = null;
    switch (type) {
      case "daily":
        data = dailyEventList
          .map((el, i) => {
            if (i == 0) {
              return `(${moment(el.actualTime).format("LL")}${
                dailyEventList.length == 1 ? ")" : ""
              }`;
            } else if (i == dailyEventList.length - 1) {
              return `${moment(el.actualTime).format("LL")})`;
            }
          })
          .filter(Boolean);
        break;
      case "weekly":
        data = weekDaysEventList
          .map((el, i) => {
            if (i == 0) {
              return `(${moment(el.actualTime).format("LL")}${
                weekDaysEventList.length == 1 ? ")" : ""
              } `;
            } else if (i == weekDaysEventList.length - 1) {
              return `${moment(el.actualTime).format("LL")})`;
            }
          })
          .filter(Boolean);
        break;
      case "custom":
        data = customEventList
          .map((el, i) => {
            if (i == 0) {
              return `(${moment(el.actualTime).format("LL")}${
                customEventList.length == 1 ? ")" : ""
              } `;
            } else if (i == customEventList.length - 1) {
              return `${moment(el.actualTime).format("LL")})`;
            }
          })
          .filter(Boolean);
        break;

      default:
        data = utils.intlLang("loading...");
    }
    return data;
  }
  return (
    <div>
      <div style={{ marginBottom: "10px",height:"350px",overflowY:"auto" }}>
      {showEvent(type)}
        <aside>
          <div class="tab-info">
            <div class="tab-content tab-content-info">
              {/* <!-- Single Tab --> */}
              <div id="tab2" class="tab-pane fade active in">
                <div class="info title">
                  {/* <!-- Start Course List --> */}
                  {displayAccordian === true ? (
                    // <div class="course-list-items acd-items acd-arrow">
                    //   <div class="panel-group symb" id="accordion">
                    //     <div class="panel panel-default">
                    //       <div class="panel-headings">
                    //         <h4 class="panel-title">
                    //           <a
                    //           style={{ border: "1px solid #ccc", padding: "10px" }}
                    //           data-toggle="collapse"
                    //           data-parent="#accordion"
                    //           href={`#event${1}`}
                    //           aria-expanded="false"
                    //           class="collapsed"
                    //         >
                    //           {utils.intlLang("Events Details")}{" "}
                    //           {showfromtoEvent(type).join("- ")}{" "}
                    //           <i className="fas fa-calendar-alt"></i>
                    //           </a>
                    //         </h4>
                    //       </div>
                    //       <div
                    //         id={"event1"}
                    //         class="panel-collapse collapse"
                    //         aria-expanded="false"
                    //         style={{ height: " 0px" }}
                    //       >
                    //         <div
                    //           class="panel-body"
                    //           style={{
                    //             height: "300px",
                    //             overflow: "auto",
                    //             marginBottom: "55px",
                    //           }}
                    //         >
                    //           <ul>{showEvent(type)}</ul>
                    //         </div>
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div> 
                    null
                  ) : (
                    // <>
                    //   <div
                    //     class="panel-body"
                    //     style={{
                    //       height: "400px",
                    //       overflow: "auto",
                    //       marginBottom: "24px",
                    //     }}
                    //   >
                    //     <ul>{showEvent(type)}</ul>
                    //   </div>
                    // </>
                    null
                  )}
                  {/* <!-- End Course List --> */}
                </div>
              </div>
              {/* <!-- End Single Tab --> */}
            </div>
            {/* <!-- End Tab Content --> */}
          </div>
        </aside>
      </div>
    </div>
  );
}

export default LiveSessionCourse;
