import React, { useState, useEffect } from "react";
import BbbService from "../../services/bbbService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Redirect, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import utils from "../../utils/util";
import "react-toastify/dist/ReactToastify.css";
function JoinMeetingModal(props) {
  const bbbServ = new BbbService();
  const [show, setshow] = useState(false);
  const handleShow = async () => {
    setshow(true);
    const data = {
      meetingID: props.courseId,
      studentName: props.studentName.split(" ").join(""),
    };
    try {
      const result = await bbbServ.joinMetting(data);

      setshow(false);
      window.open(result?.data?.data);
    } catch (error) {
      toast.error("Session not yet started.");
    }
  };
  const handleClose = () => setshow(false);
  
  setTimeout(() => {
    handleClose();
  }, 1000);
  return (
    <>
      <Button size="sm" onClick={handleShow}>
        {utils.intlLang("Join Live Session")}  {show && <i className="fas fa-spinner fa-spin fa-1x"></i>}
      </Button>

     
    </>
  );
}

export default JoinMeetingModal;
